import { gql } from "@apollo/client";

export const GET_LANGUAGES = gql`
query GetLanguages($status: Boolean) {
  languages(status: $status) {
    id
    label
    slug
    status
  }
}
`;

export const GET_TRANSLATION_KEYS = gql`
query GetTranslationKeys(
$first: Int,
$page: Int,
$orderBy: [QueryTranslationKeysOrderByOrderByClause!],
$where: QueryTranslationKeysWhereWhereConditions,
$text: String,
$searchField: translationKeySearchEnum,
) {
  translationKeys(
  first: $first,
  page: $page,
  orderBy: $orderBy,
  where: $where,
  text: $text,
  searchField: $searchField,
  ) {
    paginatorInfo {
      count
      total
      perPage
      currentPage
      lastPage
    }
    data {
      id
      key
      description
      help
      type
      translation {
       tr_key
       id
       type
       locale
       value
     }
   }
 }
}
`;


export const GET_TRANSLATION_SELECTION = gql`
  query GetTranslationsSelection(
    $first: Int
    $where: QueryTranslationsWhereWhereConditions
  ) {
    translations(
      first: $first
      where: $where
    ){
      __typename
      paginatorInfo{
        count
      }
      data{
        id
        type
        tr_key
        locale
        value
      }
    }
  }
`;



export const GET_TRANSLATIONS = gql`
  query GetTranslationsAndLang(
  $first: Int,
  $page: Int,
  $orderBy: [QueryTranslationsOrderByOrderByClause!],
  $where: QueryTranslationsWhereWhereConditions,
  $text: String,
  $searchField: translationSearchEnum
  ) {
    translations(
    first: $first,
    page: $page,
    orderBy: $orderBy,
    where: $where,
    searchField: $searchField,
    text: $text,
    ) {
      paginatorInfo {
        count
        currentPage
        total
        perPage
      }
      data {
        id
        tr_key
        type
        locale
        locales{
          locale
          value
        }
        translation_key {
          id
          key
          description
          help
        }
      }
    }

    languages {
      id
      label
      slug
      status
    }
  }
`;

export const GET_TRANSLATION_BY_KEY = gql`
query TranslationKey($id: ID!) {
  translationKey(id: $id) {
    id
    key
    description
    translation {
      id
      tr_key
      locale
      value
      type
    }
  }
}
`;

export const GET_TRANSLATION_BY_KEY_AND_LANG = gql`
query GetTranslationKeyAndLang(
$id: ID!
$status: Boolean
) {
  translationKey(id: $id) {
    id
    key
    description
    translation {
      id
      tr_key
      locale
      value
      type
    }
  }
  languages(status: $status) {
    id
    label
    slug
    status
  }
}
`;
