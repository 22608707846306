import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import cache from './../../../graphql/cache';



const useQueryExbnEvnts = (query) => {


  let history = useHistory();
  const { numPage } = useParams();

  const variables = {
    first: 10,
    page: +numPage ? +numPage : 1
  }

  const { loading, error, data } = useQuery(query, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const { users = {} } = !loading ? data : {};
  const { paginatorInfo = {} } = !loading ? users : {};
  const { total, perPage, currentPage } = paginatorInfo;

  const pagination = {
    current: currentPage,
    pageSize: !loading ? perPage : loading,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };

  const routePage = `/exhibition-events/`;

  const handleTableChange = (pagination) => {

    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }page-${ pagination.current }`);
    }
  }

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: data && data.eeAll,
    routePage,
    history,
    pagination,
    handleTableChange,
    rowCount: cache.readQuery({ query, variables })?.eeAll?.paginatorInfo?.count || 10
  }
};

export default useQueryExbnEvnts;
