const filterArrToWhere = (filterArr, condition) => {

  const [ whereItem, ...newArr ] = filterArr;

  if (newArr.length)
  {
    return {
      ...whereItem,
      [ condition ]: [ { ...filterArrToWhere(newArr, condition) } ]
    };
  }

  return whereItem;

};

export const gqlWhere = (filters, operator = "IN", condition = "AND") => {

  let filterArr = [];

  for (let [ key, value ] of Object.entries(filters))
  {

    if (value)
    {
      filterArr.push(
        {
          column: key.toUpperCase(),
          operator,
          value
        }
      )
    }

  }

  return filterArrToWhere(filterArr.reverse(), condition);

};


//setFilters(gqlWhere(filters))