import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, Avatar, Typography } from "antd";
import { FileImageOutlined } from '@ant-design/icons';
import KeyTranslation from '../../service/key-translation';
import normalMeta from '../../../utils/normal-meta';
import mobileSize from "../../../utils/mobile-size";
import SkeletonTable from '../../service/skeleton-table';


import "./exhibition-events-table.scss"


const { Column } = Table;
const { Text } = Typography;


const ExhibitionEventsTable = ({ pagination, dataQuery, loading, handleTableChange, routePage, rowCount }) => {



  return (
    <div className="holder-table">
      <Table size={ mobileSize('small') }
        className="main-table exhibition-table"
        // tableLayout={ mobileSize(true, 1300) ? '' : 'fixed' }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        loading={ { spinning: loading, indicator: <SkeletonTable rowCount={ rowCount } columns={ "".split.call(Array(10), ",") } /> } }
        dataSource={ dataQuery && dataQuery.data }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" dataIndex="id" key="id" />

        <Column
          className="table-avatar-link"
          title={ <KeyTranslation keyTranslation="table.global.column.head.logo" defaultValue="Logo" /> }
          render={ item => {
            const eventMetaFields = normalMeta(item.meta_fields);
            const imageUrl = !!eventMetaFields.ee_logo && eventMetaFields.ee_logo.meta_value;
            return (
              <Link
                key={ item.id }
                to={ {
                  pathname: `${ routePage }${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }>
                { !imageUrl ?
                  <Avatar shape="square" size={ 50 } icon={ <FileImageOutlined /> } /> :
                  <Avatar shape="square" size={ 50 } src={ imageUrl } /> }
              </Link>
            )
          } } />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.title" defaultValue="Title" /> }
          key="title"
          render={
            item => !item.title ?
              <Link
                to={ {
                  pathname: `${ routePage }${ item.id }`,
                  state: {
                    breadcrumbSlug: 'Event name is not filled'
                  }
                } }
              >
                <Text type="secondary">Event name is not filled</Text>
              </Link> :
              <Link
                to={ {
                  pathname: `${ routePage }${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }
              >
                { `${ item.title }` }
              </Link>
          }
        />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.url" defaultValue="URL" /> }
          render={
            item =>
              <Link
                to={ {
                  pathname: `//${ item?.uri }`,
                } }
                target="_blank"
              >
                { `${ item?.uri }` }
              </Link>
          }

        />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.start-date" defaultValue="Start date" /> }
          className="column-date" dataIndex="start_at" key="start_at" />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.end-date" defaultValue="End date" /> }
          className="column-date" dataIndex="end_at" key="end_at" />
        <Column title="Archive date" className="column-date" dataIndex="archive_at" key="archive_at" />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.status" defaultValue="Status" /> }
          dataIndex="status" key="status" />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.created" defaultValue="Created" /> }
          className="column-date" dataIndex="created_at" key="created_at" />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.action" defaultValue="Action" /> }
          render={ item => <Link to={ `${ routePage }${ item.id }` }><Button type="primary">Edit</Button></Link> } />
      </Table>
    </div >
  );
};

export default ExhibitionEventsTable;

