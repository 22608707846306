import React, { useState, useEffect } from "react";
import { Form, Button, Input, Radio, Spin } from "antd";
import { errorNotification } from "../../../result";
import SelectExbnEvnt from '../../select-exbn-evnt/select-exbn-evnt';
import EntityRemoveButton from '../../../service/delete-entity';
import KeyTranslation from '../../../service/key-translation';


const NormalTaxonomyCreateForm = ({
  dataQuery,
  loadingQuery,
  setMutationTaxonomyCreateUpdate,
  loadingMutationTaxonomyCreateUpdate,
  setMutationTaxonomyDelete,
  loadingTaxonomyDelete,
}) => {

  const [ form ] = Form.useForm();


  const initRadioVal = dataQuery && dataQuery.type;
  const [ radioValue, setRadioValue ] = useState(initRadioVal);

  useEffect(() => {
    setRadioValue(initRadioVal)
  }, [ initRadioVal ]);


  const radioOnChange = e => {
    setRadioValue(e.target.value);
  };



  const formSubmit = ({ values }) => {

    setMutationTaxonomyCreateUpdate({
      variables: {
        id: dataQuery ? dataQuery.id : undefined,
        title: values.title,
        type: values.type,
        ee_id: values.exhibitionEvent
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };


  return (

    <div className="form-container lg">
      { loadingQuery ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <Form
          className="form-container lg"
          form={ form }
          onFinish={ (values) => {
            formSubmit({ values });
          } }
          layout="vertical"
        >
          <Form.Item className="form-group">

            <SelectExbnEvnt eventStatus={ dataQuery ? dataQuery.ee_id : true } />

            <br />

            <Form.Item
              name="type"
              label={ <KeyTranslation keyTranslation="form.global.label.type" defaultValue="Type" /> }
              initialValue={ initRadioVal }
              rules={ [
                { required: true, message: 'Please select Type taxonomy' }
              ] }>
              <Radio.Group className="radio-row" onChange={ radioOnChange } value={ radioValue }>
                <Radio.Button value="stand">
                  <KeyTranslation keyTranslation="form.global.radio-btn.showroom" defaultValue="Showroom" />
                </Radio.Button>
                <Radio.Button value="product">
                  <KeyTranslation keyTranslation="form.global.radio-btn.product" defaultValue="Product" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>

            <br />

            <Form.Item
              name="title"
              label={ <KeyTranslation keyTranslation="form.global.label.title" defaultValue="Title" /> }
              initialValue={ dataQuery ? dataQuery.title : '' }
              rules={ [
                {
                  type: "string",
                },
                { required: true, message: 'Please input Taxonomy Title' }
              ] }
            >
              <Input placeholder="Enter Taxonomy Title" />
            </Form.Item>

            <br />

            <div className="d-flex justify-content-between">
              <div>
                <Button loading={ loadingMutationTaxonomyCreateUpdate } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>
                  <KeyTranslation keyTranslation="button.global.save" defaultValue="Save" />
                </Button>
              </div>

              {
                !!dataQuery.id && (

                  <EntityRemoveButton
                    nameEntity={ 'Taxonomy' }
                    dataNameEntity={ `${ dataQuery.title }` }
                    loading={ loadingTaxonomyDelete }
                    deleteMutation={ setMutationTaxonomyDelete }
                    variables={
                      {
                        id: dataQuery.id,
                      }
                    }
                  />
                )
              }
            </div>
          </Form.Item>
        </Form>
      )
      }
    </div>
  );
};


export default NormalTaxonomyCreateForm;
