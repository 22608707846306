import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, Avatar, Typography, Tag } from "antd";
import { FileImageOutlined } from '@ant-design/icons';
import mobileSize from "../../../../utils/mobile-size";
import EntityRemoveButton from '../../../service/delete-entity';
import KeyTranslation from '../../../service/key-translation';
import TableColumnSelectFilter from '../../../service/table-column-select-filter';
import SelectExbnEvnt from '../../../forms/select-exbn-evnt/select-exbn-evnt';
import SelectPackages from '../../../forms/select-packages/select-packages';
import SkeletonTable from './../../../service/skeleton-table';
import "./showrooms-table.scss"







const { Column } = Table;
const { Text } = Typography;

const ShowroomsTable = ({
  pagination,
  dataQuery,
  loading,
  handleTableChange,
  routePage,
  eventId,
  setEeIdColumnFilter,
  setPackageIdColumnFilter,
  setMutationStandDelete,
  loadingStandDelete,
  rowCount
}) => {

  return (
    <div className={ !eventId ? 'holder-table' : '' }>
      <Table size={ mobileSize('small') }
        className="main-table showrooms-table"
        tableLayout={ mobileSize(true, 1700) ? '' : 'fixed' }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        loading={ { spinning: loading, indicator: <SkeletonTable rowCount={ rowCount } columns={ "".split.call(Array(10), ",") } /> } }
        dataSource={ dataQuery && dataQuery.data }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column
          className="column-width-70"
          title="ID" dataIndex="id" key="id" sorter="true" />

        <Column
          className="column-width-100 table-avatar-link"
          title={ <KeyTranslation keyTranslation="table.global.column.head.logo" defaultValue="Logo" /> }
          render={ item => {
            const imageUrl = item.logo;
            return (
              <Link
                to={ {
                  pathname: `/showrooms/${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }
              >
                { !imageUrl ?
                  <Avatar shape="square" size={ 50 } icon={ <FileImageOutlined /> } /> :
                  <Avatar shape="square" size={ 50 } src={ imageUrl } /> }
              </Link>
            )
          } } />
        <Column
          title={ <KeyTranslation keyTranslation="table.showrooms.column.head.company-name" defaultValue="Company name" /> }
          key="title" sorter="true" render={ item => !item.title ?
            <Link
              to={ {
                pathname: `${ routePage }${ item.id }`,
                state: {
                  breadcrumbSlug: 'Company name is not filled'
                }
              } }
            >
              <Text type="secondary">Company name is not filled</Text></Link> :
            <Link
              to={ {
                pathname: `/showrooms/${ item.id }`,
                state: {
                  breadcrumbSlug: item.title
                }
              } }
            >
              { `${ item.title }` }</Link> }
        />
        { !eventId && (
          <>
            <Column
              title={ <KeyTranslation keyTranslation="table.global.column.head.description" defaultValue="Description" /> }
              key="description"
              className="column-description"
              render={ item => <span>{ item.description }</span> }

            />
            <Column
              title={ <KeyTranslation keyTranslation="table.showrooms.column.head.keywords" defaultValue="Keywords" /> }
              key="keywords" className="column-description w-200px" render={ item => <span>{ item.keywords }</span> } />
          </>
        ) }


        { !eventId &&

          TableColumnSelectFilter({
            setFilter: setPackageIdColumnFilter,
            columnTitle: <KeyTranslation keyTranslation="table.global.column.head.package" defaultValue="Package" />,
            columnKey: 'Package',
            columnSelectFilter: <SelectPackages />,
            columnRender: item => !item.package?.title ? 'This showroom does not have a package' :
              <Link
                className="link-underline-none"
                to={ {
                  pathname: `/packages/${ item.package.id }`,
                  state: {
                    breadcrumbSlug: item.package.title
                  }
                } }
              >
                <Tag color="green">{ item.package.title }</Tag>
              </Link>,
            columnFormOnFinish: (values, setFilter) => {
              setFilter(values.package_id)
            },
          })
        }

        { !eventId &&

          TableColumnSelectFilter({
            setFilter: setEeIdColumnFilter,
            columnTitle: <KeyTranslation keyTranslation="table.global.column.head.exhibition-event" defaultValue="Exhibition event" />,
            columnKey: 'Exhibition event',
            columnSelectFilter: <SelectExbnEvnt />,
            columnRender: item =>
              <Link
                to={ {
                  pathname: `/exhibition-events/${ item.exhibition_event.id }`,
                  state: {
                    breadcrumbSlug: item.exhibition_event.title
                  }
                } }
              >
                { `${ item.exhibition_event.title }` }
              </Link>
            ,
            columnFormOnFinish: (values, setFilter) => {
              setFilter(values[ 'exhibitionEvent' ])
            },
          })
        }

        <Column
          className="column-width-100"
          title={ <KeyTranslation keyTranslation="table.global.column.head.status" defaultValue="Status" /> }
          dataIndex="status"
          key="STATUS"
          filterMultiple={ false }
          filters={ [
            { text: "Draft", value: "draft" },
            { text: "Offline", value: "offline" },
            { text: "Online", value: "online" },
          ] }
        />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.created" defaultValue="Created" /> }
          className="column-date" dataIndex="created_at" key="created_at" />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.action" defaultValue="Action" /> }
          className="text-align-center" render={
            item =>
              <div className="wrap-link-btn">
                <Link
                  to={ {
                    pathname: `/showrooms/${ item.id }`,
                    state: {
                      breadcrumbSlug: item.title
                    }
                  } }
                >
                  <Button type="primary">Edit</Button>
                </Link>
                <Link to={ `//${ item.exhibition_event.uri }/showroom-${ item.id }/` } target="_blank"><Button type="default">View on site</Button></Link>

                { !!eventId &&
                  <EntityRemoveButton
                    nameEntity={ 'Showroom' }
                    dataNameEntity={ `${ item.title }` }
                    loading={ loadingStandDelete }
                    deleteMutation={ setMutationStandDelete }
                    variables={
                      {
                        stand_id: item.id,
                      }
                    }
                  />
                }
              </div>
          } />
      </Table>
    </div>
  );
};

export default ShowroomsTable;
