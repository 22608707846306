import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Spin, Radio, Typography } from "antd";
import SelectExbnEvnt from '../../../select-exbn-evnt';
import AvatarUpload from '../../../../upload/avatar/avatar-upload';
import KeyTranslation from '../../../../service/key-translation';

import './general-info-form.scss';


const GeneralInfoForm = ({
  dataQueryStand,
  loadingQueryStand,
  setMutationStandUpload,
  loadingMutationStandUpload
}) => {


  const { TextArea } = Input;

  const initRadioVal = !!dataQueryStand.id ? dataQueryStand.status : 'draft';
  const [ radioValue, setRadioValue ] = useState(initRadioVal);


  useEffect(() => {
    setRadioValue(initRadioVal)
  }, [ initRadioVal ]);



  const radioOnChange = e => {
    setRadioValue(e.target.value);
  };


  const eventStatus = dataQueryStand && dataQueryStand.exhibition_event.id;
  const imageUrl = !!dataQueryStand && dataQueryStand.logo;


  const wrappСonditions = (
    <>
      <SelectExbnEvnt eventStatus={ eventStatus } />

      <Form.Item
        name="сompany_name-field"
        label={ <KeyTranslation keyTranslation="form.global.label.company-name" defaultValue="Company name" /> }
        initialValue={ dataQueryStand.title }
        rules={ [
          {
            type: "string"

          },
          { required: true, message: 'Please input Company name' }
        ] }
      >
        <Input placeholder="Enter your Company name" />
      </Form.Item>

      <Form.Item
        initialValue={ radioValue }
        name="company_status-field"
        label={ <KeyTranslation keyTranslation="form.global.label.status" defaultValue="Status" /> }
        style={ { margin: "10px 0" } }>
        <Radio.Group className="radio-row" onChange={ radioOnChange } value={ radioValue }>
          <Radio value="draft">draft</Radio>
          <Radio value="offline">offline</Radio>
          <Radio value="online">online</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );

  return (
    <div>
      { loadingQueryStand ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <>

          <Typography.Link className="text-decorator">
            <h6><KeyTranslation keyTranslation="heading.small.form.showroom.general-info" defaultValue="General info" /></h6>
            <div className="separator"></div>
          </Typography.Link>

          <Form.Item className="form-group">
            { !!dataQueryStand.id && (
              <Form.Item
                label={ <KeyTranslation keyTranslation="form.showroom.label.company-logo" defaultValue="Company logo" /> }
                name="company_logo-image-unset"
                initialValue={ !!imageUrl ? imageUrl : null }
                rules={
                  [
                    {
                      required: true,
                      message: 'Please upload Company logo!',
                    }
                  ]
                }
              >
                <AvatarUpload
                  size={ 140 }
                  image={ imageUrl }
                  loading={ loadingMutationStandUpload }
                  uploadMutation={ setMutationStandUpload }
                  variables={
                    {
                      input: {
                        stand_id: dataQueryStand.id,
                        meta_key: "logo",
                      }
                    }
                  }
                  extraClass={ "contain" }
                  uploadButtonText={ <KeyTranslation keyTranslation="button.showroom.upload-company-logo" defaultValue="Upload Company logo" /> }
                  shape={ "square" }
                />
              </Form.Item>
            ) }
            {
              !!dataQueryStand
                ?
                <div>
                  { wrappСonditions }
                </div>
                :
                wrappСonditions
            }

            <div style={ { padding: '20px 0' } } > Showroom package: {
              !dataQueryStand.package?.title ?

                <strong>Not set</strong>
                :

                <Link
                  className="form-link"
                  to={ {
                    pathname: `/packages/${ dataQueryStand.package?.id }`,
                    state: {
                      breadcrumbSlug: dataQueryStand.package?.title
                    }
                  } }
                >
                  <Typography.Text type="success">
                    <strong>{ dataQueryStand.package?.title }</strong>
                  </Typography.Text>
                </Link>

            }</div>

            <Form.Item
              initialValue={ dataQueryStand.description }
              style={ { gridColumn: 'span 2' } }
              name="company_description-field"
              label={ <KeyTranslation keyTranslation="form.showroom.label.short-company-description" defaultValue="Short company description" /> }
            >
              <TextArea
                maxLength={ 200 }
                placeholder="Enter short company description"
                autoSize={ { minRows: 3, maxRows: 5 } }
                showCount={
                  { formatter: ({ count, maxLength }) => maxLength - count }
                }
              />
            </Form.Item>
          </Form.Item>
        </>
      )
      }
    </div>
  );
}

export default GeneralInfoForm;
