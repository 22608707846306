import React from "react";
import { Link } from "react-router-dom";
import { Table, Typography, Button } from "antd";
import mobileSize from "../../../../utils/mobile-size";
import KeyTranslation from '../../../service/key-translation';
import SkeletonTable from '../../../service/skeleton-table';
import "./taxonomies-table.scss"



const { Column } = Table;
const { Text } = Typography;


const TaxonomiesTable = ({ dataQuery, loadingQuery, pagination, handleTableChange }) => {

  return (
    <div className="holder-table">
      <Table size={ mobileSize('small') }
        className="main-table"
        tableLayout={ mobileSize(true, 1300) ? '' : 'fixed' }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange({ pagination, filters, sorter, page })
        }
        loading={ { spinning: loadingQuery, indicator: <SkeletonTable rowCount={ 13 } columns={ "".split.call(Array(10), ",") } /> } }
        dataSource={ dataQuery }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column
          className="column-width-70"
          title="ID" sorter="true" dataIndex="id" key="id" />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.title" defaultValue="Title" /> }
          sorter="true" key="title" render={
            item => !item.title ?
              <Link
                to={ {
                  pathname: `/taxonomy/${ item.id }`,
                  state: {
                    breadcrumbSlug: 'Product name is not filled'
                  }
                } }
              >
                <Text type="secondary">Product name is not filled</Text>
              </Link> :
              <Link
                to={ {
                  pathname: `/taxonomy/${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }
              >
                { `${ item.title }` }
              </Link> } />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.type" defaultValue="Type" /> }
          key="TYPE"
          render={ item => `${ item.type === 'stand' ? 'showroom' : item.type }` }
          filterMultiple={ false }
          filters={ [
            { text: "Showroom", value: "stand" },
            { text: "Product", value: "product" },
          ] }
        />
        <Column
          title={ <KeyTranslation keyTranslation="table.taxonomies.column.head.count-term" defaultValue="Count term" /> }
          render={ item => item.count_term === null ? '0' : item.count_term } />
        <Column title="Count linked to" render={ item => item.count_obj === null ? '0' : item.count_obj } />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.exhibition-event" defaultValue="Exhibition event" /> }
          render={ item =>
            <Link
              to={ {
                pathname: `exhibition-events/${ item.exhibition_event.id }`,
                state: {
                  breadcrumbSlug: item.exhibition_event.title
                }
              } }
            >
              { `${ item.exhibition_event.title }` }
            </Link> } />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.created" defaultValue="Created" /> }
          dataIndex="created_at" key="created_at" />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.action" defaultValue="Action" /> }
          render={ item =>
            <Link
              to={ {
                pathname: `/taxonomy/${ item.id }`,
                state: {
                  breadcrumbSlug: item.title
                }
              } }
            >
              <Button type="primary">Edit</Button>
            </Link> } />
      </Table>
    </div>
  );
};

export default TaxonomiesTable;