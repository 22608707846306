import { gql } from "@apollo/client";


export const GET_PLATFORM_PARAMS = gql`
  query GetPlatformParams {
    platformParams @client
  }
`;


export const GET_AUTH_TOKEN = gql`
  query GetAuthToken {
    authToken @client
  }
`;

export const clientSchemaExtensions = gql`

    directive @client on FIELD

    extend type Query {
        platformParams: Mixed
    }
`;
