import React from "react";
import { useQuery } from "@apollo/client";
import AuthorizationPage from "../../pages/authorization/authorization-page";
import { GET_AUTH_TOKEN } from "../../graphql/query/local-store-gql";
import "./authentication.scss";

const Authentication = ({ children }) => {
  const { data: { authToken } } = useQuery(GET_AUTH_TOKEN);
  return <>{ authToken ? children : <AuthorizationPage /> }</>;
};

export default Authentication;


