import { useRef, useEffect } from "react";



export function useEventListener(eventName, handler, element = window) {

    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [ handler ]);
    useEffect(
        () => {

            const isSupported = element && element.addEventListener;
            if (!isSupported) return;
            const eventListener = (event) => savedHandler.current(event);
            element.addEventListener(eventName, eventListener);

            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [ eventName, element ]
    );
}



/* Usage

example 1

import { useState, useCallback } from "react";

    function App() {

        const [coords, setCoords] = useState({ x: 0, y: 0 });

        const handler = useCallback(
        ({ clientX, clientY }) => {

            setCoords({ x: clientX, y: clientY });
        },
        [setCoords]
        );

        let element = document.querySelector('.elem');
        //let elements = document.querySelectorAll('ul > li:last-child');

        useEventListener("mousemove", handler, element); // element optional parameter by default element = window;

        return (
        <h1>
            The mouse position is ({coords.x}, {coords.y})
        </h1>
        );
    }

    https://learn.javascript.ru/event-delegation

*/