import React from "react";
import { Link } from "react-router-dom";
import { Form, Select, Button, Spin, Typography } from "antd";
import GeneralInfoForm from './general-info-form';
import ContactInfoForm from './contact-info-form';
import SocialLinksForm from './social-links-form';
import useMutationStandCreateUpdate from '../hooks/use-mutation-stand-create-update';
import useMutationStandCreateAndMetaSet from './../hooks/use-mutation-stand-create-and-meta-set';
import useMutationStandMetaUpload from '../hooks/use-mutation-stand-meta-upload';
import errorNotification from '../../../result/error-notification';
import metaFields from './../../../../utils/meta-fields';
import EntityRemoveButton from './../../../service/delete-entity';
import useMutationStandDelete from '../hooks/use-mutation-stand-delete';
import TaxanomyInput from '../../taxonomy-input';
import packageRules from './../../../../utils/package-rules';
import KeyTranslation from '../../../service/key-translation';

import './company-profile-form.scss';


import {
  STAND_CREATE_AND_META_SET,
  STAND_META_UPLOAD,
  STAND_CREATE_UPDATE,
  STAND_DELETE
} from '../../../../graphql/mutation/showroom-gql'

const { getValue, normalize } = metaFields;


const CompanyProfileForm = ({ dataQueryStand, loadingQueryStand }) => {

  const [ form ] = Form.useForm();

  const {
    _setMutationVariableCreateUpdate,
    // loadingMutationCreateUpdate,

  } = useMutationStandCreateUpdate(STAND_CREATE_UPDATE, dataQueryStand);

  const {
    _setMutationVariableStandCreateAndMetaSet,
    loadingMutationStandCreateAndMetaSet

  } = useMutationStandCreateAndMetaSet(STAND_CREATE_AND_META_SET, dataQueryStand);

  const {
    _setMutationStandUpload,
    loadingMutationStandUpload,

  } = useMutationStandMetaUpload(STAND_META_UPLOAD, dataQueryStand, form);

  const {
    _setMutationStandDelete,
    loadingStandDelete

  } = useMutationStandDelete(STAND_DELETE);


  const metaStandTaxonomiesId = normalize(
    dataQueryStand && dataQueryStand.exhibition_event ?
      dataQueryStand.exhibition_event.meta_fields : false
  )
  const arrStandTaxonomiesId = getValue(metaStandTaxonomiesId, "showroom_taxonomies")
  const arrMapTaxonomiesId = getValue(metaStandTaxonomiesId, "map_taxonomy")

  const formSubmit = ({ values }) => {

    let { metaValues, fieldValues } = metaFields.parseForm(values);
    metaValues = metaFields.filterEmpty(metaValues, dataQueryStand.meta_fields);


    let termIds = [];

    let is_moderated;

    if (fieldValues.company_status === 'online')
    {
      is_moderated = true
    } else
    {
      is_moderated = false
    }


    for (let [ key, value ] of Object.entries(fieldValues))
    {

      if (key.indexOf('stand_terms') !== -1)
      {
        termIds = [ ...termIds, ...value ];
      }
    }

    if (!!dataQueryStand.id)
    {
      _setMutationVariableStandCreateAndMetaSet({
        variables: {
          stand_id: dataQueryStand.id,
          metaInput: [ ...metaValues ],
          standInput: {
            id: dataQueryStand.id,
            ee_id: values.exhibitionEvent,
            title: fieldValues.сompany_name,
            status: fieldValues.company_status,
            description: fieldValues.company_description,
            keywords: fieldValues.keywords.join(','),
            is_moderated
          },
          term_id: termIds
        }
      }).catch((errorMutation) => {
        errorNotification(errorMutation);
      });
    } else
    {
      _setMutationVariableCreateUpdate({
        variables: {
          standInput: {
            ee_id: values.exhibitionEvent,
            title: fieldValues.сompany_name,
            status: fieldValues.company_status,
            description: fieldValues.company_description,
            is_moderated
          }
        }
      }).catch((errorMutation) => {
        errorNotification(errorMutation);
      });
    }
  };



  //Package rules for 'Search keywords'

  const { packageRuleForStand } = packageRules;
  const {
    permittedNumbersStand,
    activePackageRule,
    maxAmountPackageRule
  } = packageRuleForStand(dataQueryStand, 'stand_keyword_amount');



  return (

    <div className="form-container lg">
      { loadingQueryStand ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <Form
          form={ form }
          onFinish={ (values) => {
            formSubmit({ values });
          } }
          layout="vertical"
          className=""
        >
          <GeneralInfoForm
            dataQueryStand={ dataQueryStand }
            loadingQueryStand={ loadingQueryStand }
            setMutationStandUpload={ _setMutationStandUpload }
            loadingMutationStandUpload={ loadingMutationStandUpload }
          />

          { !!dataQueryStand &&
            <>

              <ContactInfoForm
                dataQueryStand={ dataQueryStand }
                loadingQueryStand={ loadingQueryStand }
                setMutationStandUpload={ _setMutationStandUpload }
                loadingMutationStandUpload={ loadingMutationStandUpload }
              />

              <SocialLinksForm
                dataQueryStand={ dataQueryStand }
                loadingQueryStand={ loadingQueryStand }
                setMutationStandUpload={ _setMutationStandUpload }
                loadingMutationStandUpload={ loadingMutationStandUpload }
              />

              <Typography.Link className="text-decorator">
                <h6><KeyTranslation keyTranslation="heading.small.form.global.exhibition-info" defaultValue="Exhibition info" /></h6>
                <div className="separator"></div>
              </Typography.Link>

              { arrStandTaxonomiesId.length !== 0 ?
                arrStandTaxonomiesId.map((id) => {

                  let arrTerms = dataQueryStand.terms
                    .filter((i) => i.taxonomy_id === id)
                    .map((i) => i.id)

                  return (
                    <TaxanomyInput
                      key={ id }
                      id={ id }
                      name={ `stand_terms_${ id }-field` }
                      initialValue={ arrTerms }
                      placeholder="Please select interested" />
                  )
                }
                ) : false }

              { arrMapTaxonomiesId.length !== 0 ?
                arrMapTaxonomiesId.map((id) => {

                  let arrTerms = dataQueryStand.terms
                    .filter((i) => i.taxonomy_id === id)
                    .map((i) => i.id)

                  return (
                    <TaxanomyInput
                      key={ id }
                      id={ id }
                      staticTitle={
                        {
                          condition: arrMapTaxonomiesId.length !== 0,
                          title: "Showroom on map"
                        }
                      }
                      name={ `stand_terms_${ id + 1 }-field` }
                      initialValue={ arrTerms }
                      placeholder="Please select interested" />
                  )
                }
                ) : false }


              <Form.Item
                className="keywords-field"
                initialValue={ !!dataQueryStand.keywords && dataQueryStand.keywords.length ? dataQueryStand.keywords.split(',') : [] }
                name="keywords-field"
                label={
                  <div className='d-flex-md justify-content-between'>
                    <div> <KeyTranslation keyTranslation="form.global.label.search-keywords" defaultValue="Search keywords" /> </div>
                    <div> { dataQueryStand.package?.package_rules &&
                      (permittedNumbersStand || !activePackageRule) ?
                      <Typography.Text type="danger">
                        { permittedNumbersStand && 'Maximum number of "Search keywords" reached of this package' }
                        { (permittedNumbersStand && !activePackageRule) && ' and also ' }
                        { !activePackageRule && 'Action "Search keywords" is prohibited in the rules of this package:' }

                        <Link
                          className="form-link"
                          to={ {
                            pathname: `/packages/${ dataQueryStand.package?.id }`,
                            state: {
                              breadcrumbSlug: dataQueryStand.package?.title
                            }
                          } }
                        >
                          <Typography.Text type="success">
                            <strong> "{ dataQueryStand.package?.title }" </strong>
                          </Typography.Text>
                        </Link>
                      </Typography.Text> :
                      ''
                    }
                    </div>
                  </div>
                }

                rules={ [
                  {
                    validator(_, value) {
                      if (value.length <= maxAmountPackageRule)
                      {
                        return Promise.resolve();

                      }

                      return Promise.reject('Maximum number of "Search keywords" reached of this package');
                    },
                  }
                ] }
              >
                <Select
                  mode="tags"
                  placeholder="Enter search keywords"
                  disabled={ !activePackageRule }
                  tokenSeparators={ [ ',' ] }
                  getPopupContainer={ () => null }
                >
                </Select>
              </Form.Item>
            </>
          }

          <div className="d-flex justify-content-between">
            <div>
              <Button loading={ loadingMutationStandCreateAndMetaSet } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>
                <KeyTranslation keyTranslation="button.global.save" defaultValue="Save" />
              </Button>
            </div>
            { !!dataQueryStand &&

              <div>
                <EntityRemoveButton
                  nameEntity={ 'Showroom' }
                  dataNameEntity={ `${ !dataQueryStand.title ? '' : dataQueryStand.title } and also all products attached to this stand will be deleted` }
                  loading={ loadingStandDelete }
                  deleteMutation={ _setMutationStandDelete }
                  variables={
                    {
                      stand_id: dataQueryStand.id,
                    }
                  }
                />
              </div>
            }

          </div>
        </Form>
      )
      }
    </div>
  );
};


export default CompanyProfileForm;
