import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, Avatar, Tag } from "antd";
import { FileImageOutlined } from '@ant-design/icons';
import KeyTranslation from '../../service/key-translation';
import mobileSize from "../../../utils/mobile-size";
import TableColumnSelectFilter from '../../service/table-column-select-filter';
import SelecStands from '../../forms/select-stands/select-stands';
import SelectExbnEvnt from '../../forms/select-exbn-evnt/select-exbn-evnt';
import SkeletonTable from '../../service/skeleton-table';
import isEmpty from 'lodash/isEmpty';


import "./user-table.scss"



const { Column } = Table;


const UserTable = ({ pagination, users, loading, handleTableChange, setStandId, setEeId, rowCount }) => {


  return (
    <div className="holder-table">
      <Table size={ mobileSize('small') }
        className="main-table user-table"
        tableLayout={ mobileSize(true, 1500) ? '' : 'fixed' }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        loading={ { spinning: loading, indicator: <SkeletonTable rowCount={ rowCount } columns={ "".split.call(Array(10), ",") } /> } }
        dataSource={ users.data }
        rowKey={ user => user.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column
          className="column-width-70"
          title={ <KeyTranslation keyTranslation="table.users.column.head.id" defaultValue="ID" /> } sorter="true" dataIndex="id" key="id" />

        <Column
          className="column-width-100 table-avatar-link"
          title={ <KeyTranslation keyTranslation="table.global.column.head.avatar" defaultValue="Avatar" /> }
          render={ user => {

            return (
              <Link
                to={ {
                  pathname: `/users/id-${ user.id }-${ user.name }-${ user.surname }`.toLowerCase(),
                  state: {
                    // breadcrumbSlug: `${ user.name } ${ user.surname }`
                  }
                } }>
                { !user.avatar ?
                  <Avatar shape="square" size={ 50 } icon={ <FileImageOutlined /> } /> :
                  <Avatar shape="square" size={ 50 } src={ user.avatar } /> }
              </Link>
            )
          } } />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.full-name" defaultValue="Full name" /> }
          sorter="true" key="name" render={ user =>
            <Link
              to={ {
                pathname: `/users/id-${ user.id }-${ user.name }-${ user.surname }`.toLowerCase(),
                state: {
                  // breadcrumbSlug: `${ user.name } ${ user.surname }`
                }
              } }>
              { `${ user.name } ${ user.surname }` }
            </Link>
          } />
        <Column
          className="column-width-300"
          title={ <KeyTranslation keyTranslation="table.users.column.head.e-mail" defaultValue="E-Mail" /> } dataIndex="email_original" key="email_original" />

        { TableColumnSelectFilter({
          setFilter: setEeId,
          columnTitle: <KeyTranslation keyTranslation="table.global.column.head.exhibition-event" defaultValue="Exhibition event" />,
          columnKey: 'Exhibition event',
          columnSelectFilter: <SelectExbnEvnt />,
          columnRender: user => {
            const eventStatus = (!isEmpty(user.exhibition_event));
            const arrExbEvnt = eventStatus && user.exhibition_event.map(item =>
            (
              <Link
                key={ item.id }
                to={ {
                  pathname: `/exhibition-events/${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }>
                { `${ item.title }` }
              </Link>
            )
            );

            return (
              arrExbEvnt
            )
          }
          ,
          columnFormOnFinish: (values, setFilter) => {
            setFilter(values[ 'exhibitionEvent' ])
          },
        }) }

        { TableColumnSelectFilter({
          setFilter: setStandId,
          columnTitle: <KeyTranslation keyTranslation="table.global.column.head.showroom" defaultValue="Showroom" />,
          columnKey: 'Showroom',
          columnSelectFilter: <SelecStands />,
          columnRender: user => {
            const standStatus = (!isEmpty(user.stand));
            const arrStand = standStatus && user.stand.map(item =>
            (
              <Link
                key={ item.id }
                to={ {
                  pathname: `/showrooms/${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }
              >
                { `${ item.title }` }
              </Link>
            )
            );

            return (
              arrStand
            )
          }
          ,
          columnFormOnFinish: (values, setFilter) => {
            setFilter(values[ 'stand_id--unset' ].value)
          },
        }) }

        <Column title={ <KeyTranslation keyTranslation="table.global.column.head.status" defaultValue="Status" /> } dataIndex="status" key="STATUS"
          filters={ [
            { text: "New", value: "new" },
            { text: "Active", value: "active" },
            { text: "Close", value: "close" },
            { text: "Block", value: "block" },
          ] }
        />
        <Column title={ <KeyTranslation keyTranslation="table.global.column.head.role" defaultValue="Role" /> } key="ROLE"
          render={ user => <Tag color="green">{ user.role }</Tag> }
          filters={ [
            { text: "Administrator", value: "admin" },
            { text: "Editor", value: "editor" },
            { text: "Exhibitor", value: "exhibitor" },
            { text: "Visitor", value: "visitor" }
          ] }
        />
        <Column title={ <KeyTranslation keyTranslation="table.global.column.head.created" defaultValue="Created" /> } sorter="true" dataIndex="created_at" key="created_at" />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.action" defaultValue="Action" /> }
          render={ user => <Link to={ `/users/id-${ user.id }-${ user.name }-${ user.surname }`.toLowerCase() }><Button type="primary">Edit</Button></Link> } />
      </Table>
    </div>
  );
};

export default UserTable;