import React from "react";
import { Link } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Avatar } from "antd";
import { useMutation } from "@apollo/client";
import KeyTranslation from '../service/key-translation';
import { isLoggedInVar } from "graphql/cache";
import { USER_LOGOUT } from "../../graphql/mutation/user-gql";

import "./user-dropdown.scss";





const UserDropdown = ({ getMe }) => {

  const { me: { avatar, email_original, id, name, surname } } = getMe;
  const [ userLogoutRequest ] = useMutation(USER_LOGOUT);



  const menu = (
    <Menu>
      <Menu.Item>
        <span>
          <Link style={ { color: 'rgba(0,0,0,.65)' } } to={ `/users/id-${ id }-${ name }-${ surname }`.toLowerCase() }>
            <KeyTranslation
              keyTranslation="user-dropdown.your-profile"
              defaultValue="Your Profile" />
          </Link>
        </span>
      </Menu.Item>
      <Menu.Item>
        <span
          onClick={ () => {
            userLogoutRequest().then(() => {
              localStorage.clear();
              isLoggedInVar(false);
            });
          } }
        >
          <KeyTranslation
            keyTranslation="user-dropdown.log-out"
            defaultValue="Log out" />
        </span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={ menu } trigger={ [ "click" ] }>
      <a className="ant-dropdown-link user-dropdown" href="/">
        <div className="box-img">
          <Avatar src={ avatar } size="30"></Avatar>
        </div>
        <span className="text ellipsis">{ email_original }</span>
        <span style={ { marginLeft: '5px' } }> <DownOutlined /></span>
      </a>
    </Dropdown>
  );
};

export default UserDropdown;

