import React from 'react';
import { ShowroomTabs } from '../components/tabs';

const ShowroomPage = () => {

    return (
        <div className="page-holder bg-white">
            <ShowroomTabs />
        </div>
    );
};

export default ShowroomPage;
