import React from "react";
import { Link } from 'react-router-dom';
import { Table, Typography } from "antd";
import mobileSize from "../../../../utils/mobile-size";
import PackageRequestForm from '../../../forms/packages-form/package-request-form';
import EditModal from '../../../service/edit-modal';
import KeyTranslation from '../../../service/key-translation';
import SelectPackages from '../../../forms/select-packages';
import TableColumnSelectFilter from '../../../service/table-column-select-filter';
import SkeletonTable from '../../../service/skeleton-table';
import "./package-requests-table.scss"







const { Column } = Table;
const { Text } = Typography;


const PackageRequestsTable = ({
  dataQuery,
  loadingQuery,
  pagination,
  handleTableChange,
  setPackageId,
  rowCount
}) => {

 

  return (
    <div className="holder-table">
      <Table size={ mobileSize('small') }
        className="main-table"
        tableLayout={ mobileSize(true, 1300) ? '' : 'fixed' }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        loading={ { spinning: loadingQuery, indicator: <SkeletonTable rowCount={ rowCount } columns={ "".split.call(Array(10), ",") } /> } }
        dataSource={ dataQuery }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column
          className="column-width-70"
          title="ID" sorter="true" dataIndex="id" key="id" />

        { TableColumnSelectFilter({
          setFilter: setPackageId,
          columnTitle: <KeyTranslation keyTranslation="table.global.column.head.package" defaultValue="Package" />,
          columnKey: 'Package',
          columnSelectFilter: <SelectPackages />,
          columnRender: item =>
            <Link
              to={ {
                pathname: `/packages/${ item.package_id }`,
                state: {
                  breadcrumbSlug: item.package.title
                }
              } }
            >
              { `${ item.package.title }` }
            </Link>
          ,
          columnFormOnFinish: (values, setFilter) => {
            setFilter(values.package_id)
          },
        })
        }

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.showroom" defaultValue="Showroom" /> }
          render={ item =>
            <>
              <Link
                to={ {
                  pathname: `/showrooms/${ item.stand_id }`,
                  state: {
                    breadcrumbSlug: item.stand.title
                  }
                } }
              >
                { `${ item.stand.title }` }
              </Link>
              <br />
              {
                item.stand.package?.title ?
                  <>
                    Package: <Text type="success">{ item.stand.package?.title }</Text>
                  </>
                  :
                  <>
                    Package: <Text type="secondary">This stand does not have a package</Text>
                  </>
              }

            </>
          }
        />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.user" defaultValue="User" /> }
          render={ item =>
            <Link
              to={ {
                pathname: `/users/id-${ item.user_id }-${ item.user.name }-${ item.user.surname }`.toLowerCase(),
              } }
            >
              { `${ item.user.name } ${ item.user.surname }` }
            </Link> }
        />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.created" defaultValue="Created" /> }
          sorter="true"
          dataIndex="created_at" key="created_at" />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.action" defaultValue="Action" /> }
          className="text-align-center" render={
            item =>
              <div className="wrap-link-btn">

                <EditModal
                  modalClassName={ 'modal-package-requests-table' }
                  valueBtn={ 'Approve' }
                  titleModal={ <> Are you sure you want to <Text type="success">Approve</Text> a request from <br /> <Text type="success">"{ item.stand.title }"</Text>? </> }>
                  <PackageRequestForm dataQuery={ dataQuery } loadingQuery={ loadingQuery } reqstId={ item.id } requestStatus={ 'approved' } />
                </EditModal>


                <EditModal
                  modalClassName={ 'modal-package-requests-table' }
                  valueBtn={ 'Decline' }
                  typeBtn={ 'danger' }
                  titleModal={ <> Are you sure you want to <Text type="danger">Decline</Text> a request from <br /> <Text type="success">"{ item.stand.title }"</Text>? </> }>
                  <PackageRequestForm dataQuery={ dataQuery } loadingQuery={ loadingQuery } reqstId={ item.id } requestStatus={ 'declined' } />
                </EditModal>
              </div>
          } />

      </Table>
    </div>
  );
};

export default PackageRequestsTable;
