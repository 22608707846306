import React from "react";
import ProductsTable from "./products-table";
import SearchForm from "../../forms/search-form"
import { Layout } from 'antd';
import { GET_PRODUCTS } from "../../../graphql/query/products-gql";
import useQueryProducts from './hooks/use-query-products';
import KeyTranslation from '../../service/key-translation';
import './products.scss';



const { Sider, Content } = Layout;

const Products = () => {

  const {
    loadingQuery,
    dataQuery,
    history,
    setSearchVal,
    setStandId,
    setEeId,
    routePage,
    pagination,
    handleTableChange,
    rowCount
  } = useQueryProducts(GET_PRODUCTS);



  return (

    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1><KeyTranslation keyTranslation="heading.large.products" defaultValue="Products" /></h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } />
        </Sider>
      </Layout>
      <ProductsTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        setStandId={ setStandId }
        setEeId={ setEeId }
        handleTableChange={ handleTableChange }
        rowCount={ rowCount }
      />
    </>
  );
};

export default Products;
