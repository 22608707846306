import { gql } from "@apollo/client";

export const GET_STANDS = gql`
query GetStands(
$first: Int,
$page: Int,
$text: String,
$where: QueryStandsWhereWhereConditions,
$orderBy: [QueryStandsOrderByOrderByClause!]
) {
  stands(
  first: $first,
  page: $page,
  text: $text,
  where: $where,
  orderBy: $orderBy
  ) {
    paginatorInfo {
      count
      total
      perPage
      lastPage
      currentPage
    }
    data {
      id
      logo
      title
      description
      keywords
      status
      created_at
      exhibition_event {
        id
        title
        uri
      }
      package {
        id
        title
      }
    }
  }
}
`;

export const GET_STAND_PRODUCTS = gql`
query GetStandProducts (
$stand_id: ID,!
$top: Boolean,
$type: String,
$status: ProductStatusEnum,
$orderBy: [QueryStandProductsOrderByOrderByClause!],
$first: Int,
$page: Int
) {
  standProducts(
  stand_id: $stand_id,
  top: $top,
  type: $type,
  status: $status,
  orderBy: $orderBy,
  first: $first,
  page: $page
  ) {
    paginatorInfo {
      total
      perPage
      lastPage
      currentPage
    }
    data {
      id
      cover
      title
      type
      is_top
      status
    }
  }
}
`;

export const GET_STAND_FOR_SELECT = gql`
query GetStandsForSelect($first: Int, $page: Int, $text: String) {
  stands(first: $first, page: $page, text: $text) {
    paginatorInfo {
      total
      perPage
      lastPage
      currentPage
    }
    data {
      id
      title
      package {
        id
        title
        package_rules {
          package_id
          id
          rule_key
          min_value
          max_value
          type
          active
        }
       }
    }
  }
}
`;


export const GET_STAND = gql`
query Stand($standId: ID) {
  stand(id: $standId) {
    id
    ee_id
    logo
    title
    status
    description
    keywords
    is_moderated
    products {
      id
    }
    exhibition_event {
      id
      title
      uri
      meta_fields {
        id
        ee_id
        meta_key
        meta_type
        meta_value
        meta_group
      }
    }
    terms {
      taxonomy_id
      id
      title
    }
    exhibitors {
     id
     name
     surname
     avatar
     email
     email_original
     role
     stand_permission {
      id
      visible
      permission
    }
  }
  meta_fields {
    id
    stand_id
    meta_key
    meta_value
    meta_group
  }
  package {
    id
    title
    package_rules {
      package_id
      id
      rule_key
      min_value
      max_value
      type
      active
    }
   }
   package_request {
      id
      package {
        id
        title
      }
      user {
        id
        name
        surname
      }
      moderator {
        id
        name
        surname
      }
      message_answer
      status
      created_at
    }
  }
}
`;


export const GET_STAND_META_BY_GROUP = gql`
query StandMetaByGroup(
$stand_id: ID!,
$meta_group: [String]
){
  standMetaByGroup(
  stand_id: $stand_id,
  meta_group: $meta_group
  ){
    id
    stand_id
    meta_key
    meta_type
    meta_value
    meta_group
  }
}
`;

export const GET_STAND_AMOUNTS = gql`
  query StandAmounts($stand_id: ID!){
    standAmounts(stand_id: $stand_id){
        product_amount
        product_amount_author
        stand_keyword_amount
        video_chat_room_amount
        team_members_amount
        stand_document_amount
      }
    }
`;








