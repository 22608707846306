import React, { useRef } from "react";
import { Link, withRouter } from 'react-router-dom';
import { MenuUnfoldOutlined, MenuFoldOutlined, RightOutlined, HomeOutlined } from '@ant-design/icons';
import UserDropdown from "../user-dropdown";
import Routes from "../../routes";
import logo from "../../assets/desktop_logo.png";
import { Breadcrumb, Typography } from "antd";
import MainMenu from "../main-menu";
import LanguageSelector from '../localization/language-selector/language-selector';
import mobileSize from '../../utils/mobile-size';
import { useOnClickOutside } from '../hooks/use-on-click-outside';
import { useOnEventNode } from '../hooks/use-on-event-node';

import "./main-layout.scss";



const MainLayout = withRouter(props => {

  const { getMe } = props;
  const { location: { pathname } } = props;

  const pathSnippets = pathname.split('/').filter(i => i);
  const breadcrumbSlug = props.location.state?.breadcrumbSlug;

  if (breadcrumbSlug)
  {
    pathSnippets.pop()
  }

  const extraBreadcrumbItems = pathSnippets.map((_, index, item) => {
    const url = `/${ pathSnippets.slice(0, index + 1).join('/') }`;
    const itemName = item[ index ];

    return (
      <Breadcrumb.Item key={ url }>
        <Link to={ url }>{ itemName.replace(/-/g, ' ') }</Link>
      </Breadcrumb.Item>

    );
  });

  const entityTitleBreadcrumbItems = breadcrumbSlug && (
    <Breadcrumb.Item key={ breadcrumbSlug }>
      { breadcrumbSlug }
    </Breadcrumb.Item>
  );

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeOutlined style={ { fontSize: '16px' } } />
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems, entityTitleBreadcrumbItems);

  const body = document.body;
  const sizeWindow = mobileSize(true, 992);

  const toggle = () => sizeWindow && body.classList.toggle("nav-open");

  const refMainNav = useRef();
  useOnClickOutside(refMainNav, () => body.classList.remove("nav-open"));

  useOnEventNode({
    delegationSelector: '#main-nav',
    targetSelector: '.ant-menu-item-only-child, .logo, .nav-opener',
    eventName: 'click',
    callback: toggle
  });

  return (
    <>
      <header id="header">
        <Typography.Link onClick={ toggle } className="nav-opener">
          <MenuUnfoldOutlined />
        </Typography.Link>
        <div className="d-flex flex-auto-width">
          <UserDropdown getMe={ getMe } />
          <LanguageSelector getMe={ getMe } />
        </div>
      </header>

      <aside id="main-nav" ref={ refMainNav }>
        <Typography.Link className="nav-opener">
          <MenuFoldOutlined />
        </Typography.Link>
        <Link className="logo" to="/">
          <img src={ logo } alt="fairnet" />
        </Link>
        <MainMenu getMe={ getMe } />
      </aside>

      <main id="main">
        {
          pathname !== "/" &&
          <Breadcrumb
            separator={ <RightOutlined style={ { fontSize: '11px' } } /> }
            className="main-breadcrumb">
            { breadcrumbItems }
          </Breadcrumb>
        }

        <Routes />
      </main>

      <footer id="footer">
        <span>Copyright &#169; 2021
          <a href="https://www.leipziger-messe.de/" target="_blank" rel="noreferrer"> Leipziger Messe </a>
          Alle Rechte vorbehalten.
        </span>
      </footer>
    </>
  );
});

export default MainLayout;
