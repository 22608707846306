import React from "react";
import { Form, Button, Input, Spin } from "antd";
import { errorNotification } from "../../../result";
import SelectExbnEvnt from '../../select-exbn-evnt/select-exbn-evnt';
import EntityRemoveButton from '../../../service/delete-entity';
import UploadFile from './../../../upload/upload-files/upload-file';
import useMutationPackageFileUpload from './hooks/use-mutation-package-file-upload';
import useMutationPackageFileDelete from './hooks/use-mutation-package-file-delete';
import KeyTranslation from '../../../service/key-translation';
import {
  PACKAGE_FILE_UPLOAD,
  PACKAGE_FILE_DELETE
} from "../../../../graphql/mutation/packages-gql";

const { TextArea } = Input;

const NormalPackageCreateForm = ({
  dataQuery,
  loadingQuery,
  setMutationPackageCreateUpdate,
  loadingMutationPackageCreateUpdat,
  setMutationPackageDelete,
  loadingPackageDelete,
}) => {


  const {
    _setMutationPackageFileUpload,
    loadingPackageFileUpload

  } = useMutationPackageFileUpload(PACKAGE_FILE_UPLOAD, dataQuery);


  const {
    _setMutationPackageFileDelete,
    // loadingPackageFileDelete

  } = useMutationPackageFileDelete(PACKAGE_FILE_DELETE, dataQuery);


  const [ form ] = Form.useForm();

  const formSubmit = ({ values }) => {

    setMutationPackageCreateUpdate({
      variables: {
        input: {
          id: dataQuery ? dataQuery.id : undefined,
          title: values.title,
          price: +values.price,
          ee_id: values.exhibitionEvent,
          description: values.description,
        }
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };



  return (

    <div className="form-container lg">
      { loadingQuery ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <Form
          className=""
          name={ dataQuery?.id ? 'package-add' : 'package-update' }
          form={ form }
          onFinish={ (values) => {
            formSubmit({ values });
          } }
          layout="vertical"
        >
          <Form.Item className="form-group">
            <SelectExbnEvnt eventStatus={ dataQuery.ee_id } />
            <br />
          </Form.Item>


          <Form.Item className="form-group">

            <Form.Item
              name="title"
              label={ <KeyTranslation keyTranslation="form.global.label.title" defaultValue="Title" /> }
              initialValue={ dataQuery.title }
              rules={ [
                {
                  type: "string",
                },
                { required: true, message: 'Please input Package Title' }
              ] }
            >
              <Input placeholder="Enter Package Title" />
            </Form.Item>

            <Form.Item
              name="price"
              // label="Price"
              label={ <KeyTranslation keyTranslation="form.global.label.price" defaultValue="Price" /> }
              initialValue={ dataQuery.price }
              rules={ [
                { required: true, message: 'Please input Package Price' }
              ] }
            >
              <Input placeholder="Enter Package Price" />
            </Form.Item>

          </Form.Item>

          <Form.Item
            initialValue={ dataQuery.description }
            name="description"
            label={ <KeyTranslation keyTranslation="form.global.label.description" defaultValue="Description" /> }
          >
            <TextArea
              maxLength={ 160 }
              placeholder="Enter Description"
              autoSize={ { minRows: 3, maxRows: 5 } }
              showCount={
                { formatter: ({ count, maxLength }) => maxLength - count }
              }
            />
          </Form.Item>

          { !!dataQuery.id && (
            <Form.Item
              name="pdf"
              label={ <KeyTranslation keyTranslation="form.global.label.add-pdf-file" defaultValue="Add PDF file" /> }
              initialValue={ dataQuery.pdf }
            >
              <UploadFile
                // size={ 140 }
                url={ dataQuery.pdf }
                loading={ loadingPackageFileUpload }
                uploadMutation={ _setMutationPackageFileUpload }
                uploadButtonText={ <KeyTranslation keyTranslation="form.global.label.add-pdf-file" defaultValue="Add PDF file" /> }
                accept={ 'application/pdf' }
                variables={
                  {
                    id: dataQuery.id,
                  }
                }
                deleteMutation={ _setMutationPackageFileDelete }
                deleteVariables={
                  {
                    id: dataQuery.id,
                  }
                }
              />
            </Form.Item>
          ) }

          <div className="d-flex justify-content-between">
            <div>
              <Button loading={ loadingMutationPackageCreateUpdat } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>
                <KeyTranslation keyTranslation="button.global.save" defaultValue="Save" />
              </Button>
            </div>

            {
              !!dataQuery.id && (

                <EntityRemoveButton
                  nameEntity={ 'Package' }
                  dataNameEntity={ `${ dataQuery.title }` }
                  loading={ loadingPackageDelete }
                  deleteMutation={ setMutationPackageDelete }
                  variables={
                    {
                      id: dataQuery.id,
                    }
                  }
                />
              )
            }
          </div>

        </Form>
      )
      }
    </div>
  );
};


export default NormalPackageCreateForm;
