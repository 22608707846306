// Query Example:

import { useQuery } from "@apollo/client";


const useQueryLanguages = (query) => {

  const { loading, error, data } = useQuery(query, {
    variables: {
      languagesStatus: true
    },
  });

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: !!data && data.languages,
  }
};

export default useQueryLanguages;