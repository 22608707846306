import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import convertSortName from '../../../../utils/convert-sort-name';
import filterObj from '../../../../utils/filter-table';
import cache from './../../../../graphql/cache';



const useQueryTrnslns = (query) => {

  const routePage = `/localization/translations`
  const [ pageSize, setPageSize ] = useState(10);

  let history = useHistory();
  const { numPage } = useParams();

  const [ searchKey, setSearchKey ] = useState('tr_key');
  const [ searchVal, setSearchVal ] = useState("");
  const [ order, setOrder ] = useState({
    orderBy: [
      {
        column: "ID",
        order: 'ASC'
      }
    ]
  });

  const [ filter, setFilter ] = useState(false);

  const variables = {
    first: pageSize,
    page: +numPage ? +numPage : 1,
    ...order,
    where: { ...filter },
    text: searchVal,
    searchField: searchKey,
  };

  const { loading, error, data } = useQuery(query, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });


  const { translations, languages } = !!data && data;
  const { paginatorInfo = {} } = !loading ? translations : {};
  const { total, currentPage } = paginatorInfo;

  const pagination = {
    current: currentPage,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };



  const handleTableChange = ({ pagination, filters, sorter }) => {

    setPageSize(pagination.pageSize)

    const {
      order,
      columnKey
    } = sorter;



    setOrder({
      orderBy: [
        {
          column: !!columnKey ? columnKey.toUpperCase() : 'ID',
          order: !!convertSortName(order) ? convertSortName(order) : 'ASC'
        }
      ]
    });


    if (filterObj(filters) !== {})
    {
      setFilter(
        { ...filterObj(filters) }
      )
    } else
    {
      setFilter(false)
    }




    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }/page-${ pagination.current }`);
    }
  }


  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: data && translations.data,
    dataQueryLang: data && languages,
    setSearchVal,
    setSearchKey,
    keyTranslation: !!filter && filter,
    routePage,
    history,
    pagination,
    handleTableChange,
    rowCount: cache.readQuery({ query, variables })?.translations?.paginatorInfo?.count || 10
  }
};

export default useQueryTrnslns;
