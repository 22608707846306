import React from "react";
import { Spin } from "antd";
import useMutationStandMetaUpload from '../hooks/use-mutation-stand-meta-upload';
import { STAND_META_UPLOAD } from '../../../../graphql/mutation/showroom-gql';
import AvatarUpload from '../../../upload/avatar/avatar-upload';
import metaFields from './../../../../utils/meta-fields';
import ChoiceColor from '../../../choice-color';
import KeyTranslation from '../../../service/key-translation';



const NormalProductPageForm = ({
  loadingQueryStand,
  dataQueryStand,
  loadingMutationStandMetaSet,
  setMutationVariableStandMetaSet,

}) => {


  const {
    _setMutationStandUpload,
    loadingMutationStandUpload,

  } = useMutationStandMetaUpload(STAND_META_UPLOAD, dataQueryStand);

  const { getValue, normalize } = metaFields;
  const meta = normalize(dataQueryStand.meta_fields);


  return (

    loadingQueryStand ? (
      <div className="block-loader h-300px" >
        <Spin size="small" />
      </div>
    ) : (
      <div className="">
        <div className="showroom-image">
          <AvatarUpload
            image={ getValue(meta, "products_image_1") }
            loading={ loadingMutationStandUpload }
            uploadMutation={ _setMutationStandUpload }
            variables={
              {
                input: {
                  stand_id: dataQueryStand.id,
                  meta_key: "products_image_1",
                  meta_group: "page_headers",
                  meta_type: "image"
                }
              }
            }
            uploadButtonText={ <KeyTranslation keyTranslation="button.global.upload-page-header-image" defaultValue="Upload Page header image" /> }
            shape={ "square" }
          />
        </div>

        <br />

        <ChoiceColor
          defaultColor={ getValue(meta, "top_bg_color") }
          uploadMutation={ setMutationVariableStandMetaSet }
          loadingMutationStandMetaSet={ loadingMutationStandMetaSet }
          btnText={ <KeyTranslation keyTranslation="button.global.choose-top-product-bg-color" defaultValue="Choose top product bg color" /> }
          variables={
            {
              stand_id: dataQueryStand.id,
              input: [ {
                meta_key: "top_bg_color",
                meta_group: "products",
              } ]
            }
          }
        />
      </div>
    )
  )
}


export default NormalProductPageForm;
