import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, Typography, Avatar } from "antd";
import { FileImageOutlined } from '@ant-design/icons';
import mobileSize from "../../../../utils/mobile-size";
import link from "../../../../utils/link";
import KeyTranslation from '../../../service/key-translation';
import SkeletonTable from '../../../service/skeleton-table';
import TableColumnSelectFilter from '../../../service/table-column-select-filter';
import SelecStands from '../../../forms/select-stands/select-stands';
import SelectExbnEvnt from '../../../forms/select-exbn-evnt/select-exbn-evnt';
import "./products-table.scss"


const { Column } = Table;
const { Text } = Typography;


const ProductsTable = ({
  pagination,
  dataQuery,
  loadingQuery,
  handleTableChange,
  setStandId,
  setEeId,
  rowCount
 }) => {


  return (
    <div className="holder-table">
      <Table size={ mobileSize('small') }
        className="main-table"
        tableLayout={ mobileSize(true, 1500) ? '' : 'fixed' }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        loading={ { spinning: loadingQuery, indicator: <SkeletonTable rowCount={ rowCount } columns={ "".split.call(Array(10), ",") } /> } }
        dataSource={ dataQuery }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column
          className="column-width-70"
          title="ID" sorter="true" dataIndex="id" key="id" />

        <Column
          className="column-width-100 table-avatar-link"
          title={ <KeyTranslation keyTranslation="table.global.column.head.cover" defaultValue="Cover" /> }
          render={ item => {

            return (
              <Link
                to={ {
                  pathname: `/products/${ item.id }`,
                  state: {
                    // breadcrumbSlug: `${ item.name } ${ item.surname }`
                  }
                } }>
                { !item.cover ?
                  <Avatar shape="square" size={ 50 } icon={ <FileImageOutlined /> } /> :
                  <Avatar shape="square" size={ 50 } src={ item.cover } /> }
              </Link>
            )
          } } />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.title" defaultValue="Title" /> }
          key="title" sorter="true" render={
            item => !item.title ?
              <Link
                to={ {
                  pathname: `/products/${ item.id }`,
                  state: {
                    breadcrumbSlug: 'Product name is not filled'
                  }
                } }
              >
                <Text type="secondary">Product name is not filled</Text>
              </Link> :
              <Link
                // to={ `/products/${ item.id }` }
                to={ {
                  pathname: `/products/${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }
              >
                { `${ item.title }` }
              </Link> }
        />
        <Column
          className="column-width-100"
          title={ <KeyTranslation keyTranslation="table.global.column.head.type" defaultValue="Type" /> }
          dataIndex="type" key="type"
          filters={ [
            { text: "Book", value: "book" },
            { text: "Author", value: "author" },
            { text: "Product", value: "product" },
          ] }
        />
        <Column
          className="column-width-70"
          title={ <KeyTranslation keyTranslation="table.global.column.head.top" defaultValue="Top" /> }
          render={ item => `${ item.is_top ? 'Yes' : 'No' }` } />
        <Column
          className="column-width-70"
          title={ <KeyTranslation keyTranslation="table.global.column.head.searchable" defaultValue="Searchable" /> }
          key="title" render={ item => `${ item.is_searchable ? 'Yes' : 'No' }` }
        />
        <Column
          className="column-width-100"
          title={ <KeyTranslation keyTranslation="table.global.column.head.status" defaultValue="Status" /> }
          dataIndex="status" key="status"
          filters={ [
            { text: "Draft", value: "draft" },
            { text: "Active", value: "active" },
            { text: "Block", value: "block" },
          ] }
        />

        { TableColumnSelectFilter({
          setFilter: setStandId,
          columnTitle: <KeyTranslation keyTranslation="table.global.column.head.showroom" defaultValue="Showroom" />,
          columnKey: 'stand_id',
          columnSelectFilter: <SelecStands />,
          columnRender: item =>
            <Link
              key={ item.stand.id }
              to={ {
                pathname: `/showrooms/${ item.stand.id }`,
                state: {
                  breadcrumbSlug: item.stand.title
                }
              } }
            >
              { `${ item.stand.title }` }
            </Link>
          ,
          columnFormOnFinish: (values, setFilter) => {
            setFilter(values[ 'stand_id--unset' ].value);
          },
        }) }

        { TableColumnSelectFilter({
          setFilter: setEeId,
          columnTitle: <KeyTranslation keyTranslation="table.global.column.head.exhibition-event" defaultValue="Exhibition event" />,
          columnKey: 'ee_id',
          columnSelectFilter: <SelectExbnEvnt />,
          columnRender: item =>
            <Link key={ item.exhibition_event.id }
              to={ {
                pathname: `/exhibition-events/${ item.exhibition_event.id }`,
                state: {
                  breadcrumbSlug: item.exhibition_event.title
                }
              } }
            >
              { `${ item.exhibition_event.title }` }
            </Link>,
          columnFormOnFinish: (values, setFilter) => {
            setFilter(values[ 'exhibitionEvent' ])
          },
        }) }
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.created" defaultValue="Created" /> }
          dataIndex="created_at" key="created_at" />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.action" defaultValue="Action" /> }
          className="text-align-center" render={
            item =>
              <div className="wrap-link-btn">
                <Link
                  to={ {
                    pathname: `/products/${ item.id }`,
                    state: {
                      breadcrumbSlug: item.title
                    }
                  } }
                >
                  <Button type="primary">Edit</Button>
                </Link>
                <Link
                  to={ getProductUrl(item) }
                  target="_blank">
                  <Button type="default">View on site</Button>
                </Link>
              </div>
          } />
      </Table>
    </div>
  );
};

export default ProductsTable;



const getProductUrl = (item) => {

  switch (item.type)
  {
    case "author":
      return `//${ item.exhibition_event.uri }/showroom-${ item.stand.id }/product#author-${ item.id }`;
    default:
      return `//${ item.exhibition_event.uri }/product/${ item.id }${ item.title ? "-" + link.toSlug(item.title) : "" }`;
  }
}