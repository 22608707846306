import React from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import KeyTranslation from './../service/key-translation';
import {
  HomeOutlined,
  UserOutlined,
  UnorderedListOutlined,
  SettingOutlined,
  BarChartOutlined,
  GlobalOutlined
} from '@ant-design/icons';


import "./main-menu.scss";




const MainMenu = () => {

  const { location: { pathname } } = useHistory();
  const openKeys = pathname.split('/')[ 1 ];
  const selectedKeys = pathname.split('/')[ 2 ] || pathname;
  const { SubMenu } = Menu;


  return (

    <Menu className="main-menu" mode="inline"
      defaultOpenKeys={ [ openKeys ] }
      defaultSelectedKeys={ selectedKeys }
    >
      <Menu.Item key="dashboard"

      >
        <Link to="/">
          <HomeOutlined />
          <KeyTranslation
            keyTranslation="main-menu.dashbord"
            defaultValue="Dashboard" />
        </Link>
      </Menu.Item>

      <SubMenu
        key="users"
        title={
          <span>
            <UserOutlined />
            <KeyTranslation
              keyTranslation="main-menu.user-setup"
              defaultValue="User setup" />
          </span>
        }
      >
        <Menu.Item key="/users" >
          <Link to="/users">
            <KeyTranslation
              keyTranslation="main-menu.user-setup.all-users"
              defaultValue="All users" />
          </Link>
        </Menu.Item>
        <Menu.Item key="add-user" >
          <Link to="/users/add-user">
            <KeyTranslation
              keyTranslation="main-menu.user-setup.add-user"
              defaultValue="Add user" />
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="exhibition-events"
        title={
          <span>
            <UnorderedListOutlined />
            <KeyTranslation
              keyTranslation="main-menu.exhibition-event"
              defaultValue="Exhibition event" />
          </span>
        }
      >
        <Menu.Item key="/exhibition-events" >
          <Link to="/exhibition-events">
            <KeyTranslation
              keyTranslation="main-menu.exhibition-event.all-events"
              defaultValue="All events" />
          </Link>
        </Menu.Item>
        <Menu.Item key="add-event" >
          <Link to="/exhibition-events/add-event">
            <KeyTranslation
              keyTranslation="main-menu.exhibition-event.add-event"
              defaultValue="Add event" />
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="showrooms"
        title={
          <span>
            <UnorderedListOutlined />
            <KeyTranslation
              keyTranslation="main-menu.showrooms"
              defaultValue="Showrooms" />
          </span>
        }
      >
        <Menu.Item key="/showrooms" >
          <Link to="/showrooms">
            <KeyTranslation
              keyTranslation="main-menu.showrooms.all-showrooms"
              defaultValue="All showrooms" />
          </Link>
        </Menu.Item>
        <Menu.Item key="add-showroom" >
          <Link to="/showrooms/add-showroom">
            <KeyTranslation
              keyTranslation="main-menu.showrooms.add-showroom"
              defaultValue="Add showroom" />
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="products"
        title={
          <span>
            <UnorderedListOutlined />

            <KeyTranslation
              keyTranslation="main-menu.products"
              defaultValue="Products" />
          </span>
        }
      >
        <Menu.Item key="/products" >
          <Link to="/products">
            <KeyTranslation
              keyTranslation="main-menu.products.all-products"
              defaultValue="All products" />
          </Link>
        </Menu.Item>
        <Menu.Item key="add-product" >
          <Link to="/products/add-product">
            <KeyTranslation
              keyTranslation="main-menu.products.add-product"
              defaultValue="Add product" />
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="taxonomy"
        title={
          <span>
            <UnorderedListOutlined />

            <KeyTranslation
              keyTranslation="main-menu.taxonomy"
              defaultValue="Taxonomy" />
          </span>
        }
      >
        <Menu.Item key="/taxonomy" >
          <Link to="/taxonomy">
            <KeyTranslation
              keyTranslation="main-menu.taxonomy.all-taxonomies"
              defaultValue="All taxonomies" />
          </Link>
        </Menu.Item>
        <Menu.Item key="add-taxonomy" >
          <Link to="/taxonomy/add-taxonomy">
            <KeyTranslation
              keyTranslation="main-menu.taxonomy.add-taxonomy"
              defaultValue="Add taxonomy" />
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="packages"
        title={
          <span>
            <UnorderedListOutlined />
            <KeyTranslation
              keyTranslation="main-menu.packages"
              defaultValue="Packages" />
          </span>
        }
      >
        <Menu.Item key="/packages" >
          <Link to="/packages">
            <KeyTranslation
              keyTranslation="main-menu.packages.all-packages"
              defaultValue="All packages" />
          </Link>
        </Menu.Item>
        <Menu.Item key="add-package" >
          <Link to="/packages/add-package">
            <KeyTranslation
              keyTranslation="main-menu.packages.add-package"
              defaultValue="Add package" />
          </Link>
        </Menu.Item>
        <Menu.Item key="package-requests" >
          <Link to="/packages/package-requests">
            <KeyTranslation
              keyTranslation="main-menu.packages.package-requests"
              defaultValue="Package requests" />
          </Link>
        </Menu.Item>
        <Menu.Item key="package-requests-resolved" >
          <Link to="/packages/package-requests-resolved">
            <KeyTranslation
              keyTranslation="main-menu.packages.package-requests-resolved"
              defaultValue="Package requests resolved" />
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="analytics"
        title={
          <span>
            <BarChartOutlined />

            <span>
              <KeyTranslation
                keyTranslation="main-menu.analytics"
                defaultValue="Analytics" />
            </span>
          </span>
        }
      >
        <Menu.Item key="/customer-behavior" disabled>
          <Link to="/customer-behavior">
            <KeyTranslation
              keyTranslation="main-menu.analytics.customer-behavior"
              defaultValue="Customer behavior" />
          </Link>
        </Menu.Item>
        <Menu.Item key="/exhibition-statistics" disabled>
          <Link to="/exhibition-statistics">
            <KeyTranslation
              keyTranslation="main-menu.analytics.exhibition-statistics"
              defaultValue="Exhibition statistics" />
          </Link>
        </Menu.Item>
        <Menu.Item key="/finance-statistics" disabled>
          <Link to="/finance-statistics">
            <KeyTranslation
              keyTranslation="main-menu.analytics.finance-statistics"
              defaultValue="Finance statistics" />
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="localization"
        title={
          <span>
            <GlobalOutlined />

            <KeyTranslation
              keyTranslation="main-menu.localization"
              defaultValue="Localization" />
          </span>
        }
      >
        <Menu.Item key="languages"  >
          <Link to="/localization/languages">
            <KeyTranslation
              keyTranslation="main-menu.localization.languages"
              defaultValue="Languages" />
          </Link>
        </Menu.Item>
        <Menu.Item key="translation-keys"  >
          <Link to="/localization/translation-keys">
            <KeyTranslation
              keyTranslation="main-menu.localization.translation-keys"
              defaultValue="Translation keys" />
          </Link>
        </Menu.Item>
        <Menu.Item key="translations" >
          <Link to="/localization/translations">
            <KeyTranslation
              keyTranslation="main-menu.localization.translations"
              defaultValue="Translations" />
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="settings"
        title={
          <span>
            <SettingOutlined />

            <KeyTranslation
              keyTranslation="main-menu.admin-settings"
              defaultValue="Admin Settings" />
          </span>
        }
      >
        <Menu.Item key="/system-settings" disabled>
          <Link to="/system-settings">
            <KeyTranslation
              keyTranslation="main-menu.admin-settings.system-settings"
              defaultValue="System Settings" />
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard-config" disabled>
          <Link to="/dashboard-config">
            <KeyTranslation
              keyTranslation="main-menu.admin-settings.dashboard-config"
              defaultValue="Dashboard config." />
          </Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default MainMenu;
