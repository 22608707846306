import React from "react";
import { Layout, Select } from 'antd';
import TranslationKeysTable from "./translation-keys-table";
import SearchForm from "../../forms/search-form"
import TranslationKeysForm from '../../forms/localization-forms/translation-keys-form';
import EditModal from './../../service/edit-modal';
import useQueryKeys from './hooks/use-query-keys';
import KeyTranslation from '../../service/key-translation';
import './translation-keys.scss';

import { GET_TRANSLATION_KEYS } from "../../../graphql/query/languages-gql";

const { Sider, Content } = Layout;
const { Option } = Select;


const TranslationKeys = () => {

  const {
    loadingQuery,
    errorQuery,
    dataQuery,
    history,
    setSearchVal,
    setSearchKey,
    routePage,
    pagination,
    handleTableChange,
    rowCount
  } = useQueryKeys(GET_TRANSLATION_KEYS);


  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>
            <KeyTranslation
              keyTranslation="heading.large.translation-keys"
              defaultValue="Translation keys" />
          </h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          <EditModal valueBtn={ <KeyTranslation keyTranslation="button.global.add-key" defaultValue="Add key" /> } titleModal={ 'Create Translation Key' }>
            <TranslationKeysForm dataQuery={ dataQuery } loadingQuery={ loadingQuery } />
          </EditModal>
          <Select
            onChange={
              (value) => {
                setSearchKey(`${ value }`);
              }
            }
            showSearch
            style={ { width: 165 } }
            placeholder="Search by"
            optionFilterProp="children"
          >
            <Option value="key">Key</Option>
            <Option value="value">Translation</Option>
          </Select>
          <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } />
        </Sider>
      </Layout>
      <TranslationKeysTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        errorQuery={ errorQuery }
        handleTableChange={ handleTableChange }
        rowCount={ rowCount }
      />
    </>
  );
};

export default TranslationKeys;

