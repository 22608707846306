import React from "react";
import { Table, Tooltip, Typography, Row, Col } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import mobileSize from "../../../../utils/mobile-size";
import TranslationKeyForm from './../../../forms/localization-forms/translations-form/';
import EditModal from './../../../service/edit-modal';
import KeyTranslation from '../../../service/key-translation';
import SkeletonTable from './../../../service/skeleton-table';


import "./translations-table.scss"


const { Column } = Table;
const { Text } = Typography;

const TranslationsTable = ({ pagination, dataQuery, dataQueryLang, loadingQuery, handleTableChange, keyTranslation, rowCount }) => {


  return (
    <div className="holder-table">
      <Table size={ mobileSize('small') }
        className="main-table translations"
        tableLayout={ mobileSize(true, 1300) ? '' : 'fixed' }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange({ pagination, filters, sorter, page })
        }
        dataSource={ dataQuery }
        loading={ { spinning: loadingQuery, indicator: <SkeletonTable rowCount={ rowCount } columns={ "".split.call(Array(4), ",") } /> } }
        rowKey={ key => key.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column
          className="column-width-70"
          title="ID" key="id" dataIndex="id" sorter="true" />
        <Column
          width={ 300 }
          title={ <KeyTranslation keyTranslation="table.global.column.head.key" defaultValue="Key" /> }
          sorter="true"
          key="tr_key"
          render={
            key =>
              <Row gutter={ 16 }>
                <Col className="gutter-row" >
                  <span>{ key.tr_key }</span>
                </Col>
                <Col className="gutter-row">
                  <Tooltip overlayClassName='tooltip-width' title={ <pre>{ key.type === 'core' ? key.translation_key.help : key.translation_key.description }</pre> }>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Col>
              </Row>
          } />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.type" defaultValue="Type" /> }
          key="TYPE"
          dataIndex="type"
          filterMultiple={ false }
          filters={ [
            { text: "Admin", value: "admin" },
            { text: "Site", value: "site" },
            { text: "Core", value: "core" },
          ] }
        />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.locale" defaultValue="Locale" /> }
          key="LOCALE"
          filterMultiple={ false }
          filters={
            dataQueryLang && dataQueryLang.map(({ slug }) => {
              return {
                text: slug, value: slug
              }
            })
          }
          render={
            key => {
              const condition = !!keyTranslation.value && keyTranslation.column === 'LOCALE';
              const selectedKeyTrnslnVal = key.locales.filter(({ locale, value }) => (locale === keyTranslation.value) && (!!value));

              if (condition)
              {
                return (
                  !!selectedKeyTrnslnVal.length ?
                    <Text key={ key } type="success">{ keyTranslation.value } </Text> :
                    <Text key={ key } type="danger">{ keyTranslation.value } </Text>
                )
              }
              else
              {
                return (

                  dataQueryLang && dataQueryLang.map(({ slug }) => {
                    const notSelectedkeyTrnslnVal = key.locales.filter(({ locale, value }) => (locale === slug) && (!!value));

                    return (
                      !!notSelectedkeyTrnslnVal.length ?
                        <Text key={ slug } type="success">{ slug } </Text> :
                        <Text key={ slug } type="danger">{ slug } </Text>
                    )
                  })
                )
              }

            }
          }
        />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.action" defaultValue="Action" /> }
          render={ key => (
            <EditModal key="0" valueBtn={ 'Edit translation' } titleModal={ '' }>
              <TranslationKeyForm trnslnId={ key.id } trnslnKeyId={ key.translation_key.id } />
            </EditModal>
          ) } />
      </Table>
    </div>
  );
};

export default TranslationsTable;
