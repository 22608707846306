import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import convertSortName from './../../../../utils/convert-sort-name';
import { gqlWhere } from '../../../../utils/gql-where';
import cache from './../../../../graphql/cache';



const useQueryProducts = (query) => {

  const routePage = `/products/`;

  const [ standId, setStandId ] = useState(undefined);
  const [ eeId, setEeId ] = useState(undefined);
  const [ filterNotMod, setFilterNotMod ] = useState(undefined);
  const [ pageSize, setPageSize ] = useState(10);
  const [ searchVal, setSearchVal ] = useState("");
  const [ order, setOrder ] = useState({
    orderBy: [
      {
        column: "ID",
        order: 'ASC'
      }
    ]
  });

  const [ filter, setFilters ] = useState(false);

  const standIdToWhere = !!standId && (
    { STAND_ID: [ +standId ] }
  )

  const eeIdToWhere = !!eeId && (
    { EE_ID: [ +eeId ] }
  )

  /*eslint-disable */
  useEffect(() =>
    setFilters(gqlWhere({ ...filterNotMod, ...standIdToWhere, ...eeIdToWhere })),
    [ standId, eeId ]
  );
  /*eslint-enable */



  let history = useHistory();
  const { numPage } = useParams();

  const variables = {
    first: pageSize,
    page: +numPage ? +numPage : 1,
    where: { ...filter },
    ...order,
    text: searchVal,
  }

  const { loading, error, data } = useQuery(query, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const { products = {} } = !loading ? data : {};
  const { paginatorInfo = {} } = !loading ? products : {};
  const { total, currentPage } = paginatorInfo;

  const pagination = {
    current: currentPage,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };


  const handleTableChange = (pagination, filters, sorter) => {

    setFilterNotMod(filters)

    setPageSize(pagination.pageSize)

    const {
      order,
      columnKey
    } = sorter;

    setOrder({
      orderBy: [
        {
          column: !!columnKey ? columnKey.toUpperCase() : 'ID',
          order: !!convertSortName(order) ? convertSortName(order) : 'ASC'
        }
      ]
    });

    // setFilters(gqlWhere(filters))
    setFilters(gqlWhere({ ...filters, ...standIdToWhere, ...eeIdToWhere }))


    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }page-${ pagination.current }`);
    }
  }

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: products.data,
    setSearchVal,
    setStandId,
    setEeId,
    setFilterNotMod,
    routePage,
    history,
    pagination,
    handleTableChange,
    rowCount: cache.readQuery({ query, variables })?.products?.paginatorInfo?.count || 10
  }
};

export default useQueryProducts;
