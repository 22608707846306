import React from "react";
import { Layout, Typography } from 'antd';
import PackageRequestsStandTable from "./package-requests-stand-table";
import EditModal from '../../service/edit-modal';
import PackageAddStandFormForm from '../../forms/packages-form/package-add-stand-form';
import KeyTranslation from '../../service/key-translation';

import './package-requests-stand.scss';



const { Sider, Content } = Layout;



const PackageRequestsStand = ({ dataQuery, loadingQuery }) => {


  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1><KeyTranslation keyTranslation="heading.large.showroom-package-history" defaultValue="Showroom package history" /></h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          <EditModal valueBtn={ 'Set package' } titleModal={
            <> Choose package for <Typography.Text type="success">"{ dataQuery.title }"</Typography.Text> showroom</>
          }>
            <PackageAddStandFormForm dataQuery={ dataQuery } />
          </EditModal>
        </Sider>
      </Layout>
      <PackageRequestsStandTable
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }

      />
    </>
  );
};

export default PackageRequestsStand;
