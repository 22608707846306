import React from "react";
import UserTable from "./user-table";
import SearchForm from "../forms/search-form"
import { Layout } from 'antd';
import useQueryUsers from './hooks/use-query-users';
import KeyTranslation from '../service/key-translation';
import { GET_USERS } from "../../graphql/query/user-gql";
const { Sider, Content } = Layout;

const Users = () => {

  const {
    loadingQuery,
    dataQuery,
    history,
    setSearchVal,
    routePage,
    pagination,
    handleTableChange,
    setStandId,
    setEeId,
    rowCount

  } = useQueryUsers(GET_USERS);



  return (

    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>
            <KeyTranslation
              keyTranslation="heading.large.users"
              defaultValue="Users" />
          </h1>

        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } />
        </Sider>
      </Layout>
      <UserTable
        setStandId={ setStandId }
        setEeId={ setEeId }
        pagination={ pagination }
        users={ dataQuery }
        loading={ loadingQuery }
        handleTableChange={ handleTableChange }
        rowCount={ rowCount }
      />
    </>
  );
};

export default Users;
