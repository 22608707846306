import React from "react";
import { Typography } from 'antd';
import NormalProductUpdateAuthorForm from './normal-product-update-author-form';
import useMutationProductCreateUpdate from '../hooks/use-mutation-product-create-update';
import useMutationProductDelete from '../hooks/use-mutation-product-delete';
import KeyTranslation from '../../../service/key-translation';

import {
  PRODUCT_CREATE_UPDATE,
  PRODUCT_DELETE
} from '../../../../graphql/mutation/products-gql'


import './product-update-author-form.scss';




const ProductUpdateAuthorForm = ({
  loadingQuery,
  errorQuery,
  dataQuery
}) => {

  const {
    _setMutationProductCreateUpdate,
    loadingMutationProductCreateUpdate

  } = useMutationProductCreateUpdate(PRODUCT_CREATE_UPDATE, dataQuery);


  const {
    _setMutationProductDelete,
    loadingProductDelete

  } = useMutationProductDelete(PRODUCT_DELETE, dataQuery);


  return (
    <>
      <h3><KeyTranslation keyTranslation="heading.middle.global.edit-product" defaultValue="Edit Product" />  <Typography.Link>{ dataQuery && dataQuery.title } </Typography.Link></h3> <br />
      <NormalProductUpdateAuthorForm
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        errorQuery={ errorQuery }
        setMutationProductCreateUpdate={ _setMutationProductCreateUpdate }
        loadingMutationProductCreateUpdate={ loadingMutationProductCreateUpdate }
        setMutationProductDelete={ _setMutationProductDelete }
        loadingProductDelete={ loadingProductDelete }
      />
    </>
  );
};


export default ProductUpdateAuthorForm;
