import React from "react";
import { Layout } from 'antd';
import PackageRequestsTable from "./package-requests-table";
import useQueryPackageRequests from './hooks/use-query-package-requests';
import KeyTranslation from '../../service/key-translation';

import { GET_PACKAGE_REQUESTS } from "../../../graphql/query/packages-gql";

import './package-requests.scss';








const { Sider, Content } = Layout;



const PackageRequests = () => {


  const {
    loadingQuery,
    errorQuery,
    dataQuery,
    pagination,
    handleTableChange,
    setPackageId,
    rowCount

  } = useQueryPackageRequests(GET_PACKAGE_REQUESTS);


  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>
            <KeyTranslation
              keyTranslation="heading.large.package-requests"
              defaultValue="Package requests" />
          </h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }></Sider>
      </Layout>
      <PackageRequestsTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        errorQuery={ errorQuery }
        handleTableChange={ handleTableChange }
        setPackageId={ setPackageId }
        rowCount={ rowCount }
      />
    </>
  );
};

export default PackageRequests;
