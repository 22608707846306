import React from "react";
import RequestHandler from "../../request-handler"
import { Form, Select } from "antd";
import KeyTranslation from '../../service/key-translation';


// Hooks
import useQueryUserRoles from './hooks/use-query-user-roles';

//Query
import { GET_USER_ROLES } from './../../../graphql/query/user-gql';

//CSS
import './select-user-roles.scss';




const SelectUserRoles = ({ disabledSelectRoles }) => {

  const {
    loadingQuery,
    errorQuery,
    dataQuery,
  } = useQueryUserRoles(GET_USER_ROLES);

  const { Option } = Select;


  const selectUserRoles = dataQuery && (
    dataQuery.map((item) => {
      return (
        <Option key={ item.id } value={ item.slug }>
          <span>{ item.label }</span>
        </Option>
      )
    })
  );



  return (
    <RequestHandler
      loading={ loadingQuery }
      error={ errorQuery }
      data={ dataQuery }
    >
      <Form.Item
        name="role"
        label={ <KeyTranslation keyTranslation="form.global.label.role" defaultValue="Role" /> }
        rules={ !disabledSelectRoles && [ { required: true, message: 'Please select your role' } ] }
      >
        <Select
          disabled={ disabledSelectRoles }
          placeholder="Select your role">
          { selectUserRoles }
        </Select>
      </Form.Item>
    </RequestHandler>
  )
};

export default SelectUserRoles;