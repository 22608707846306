import React from "react";
import { Link } from "react-router-dom";
import { Table, Radio, Avatar } from "antd";
import { FileImageOutlined } from '@ant-design/icons';
import mobileSize from "../../../../utils/mobile-size";
import useMutationStandCreateUpdate from '../hooks/use-mutation-stand-create-update';
import useMutationStandDeleteExhibitor from './../hooks/use-mutation-stand-delete-exhibitor';
import errorNotification from '../../../result/error-notification';
import EntityRemoveButton from '../../../service/delete-entity';
import KeyTranslation from '../../../service/key-translation';
import { STAND_CREATE_UPDATE, STAND_DELETE_EXHIBITOR } from '../../../../graphql/mutation/showroom-gql';

import "./stand-users-table.scss"



const { Column } = Table;


const StandUserTable = ({ dataQuery, loadingQuery }) => {


  const {
    _setMutationVariableCreateUpdate,

  } = useMutationStandCreateUpdate(STAND_CREATE_UPDATE, dataQuery);

  const {
    _setMutationVariableDeleteExhibitor,
    loadingMutationDeleteExhibitor

  } = useMutationStandDeleteExhibitor(STAND_DELETE_EXHIBITOR, dataQuery);



  const handleRadioChange = ({ user_id, permission, visible }) => {

    _setMutationVariableCreateUpdate({
      variables: {
        standInput: {
          id: dataQuery.id,
          exhibitor: [
            {
              user_id: user_id,
              permission: permission,
              visible: visible
            }
          ]
        }
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };

  return (
    <div className="">
      <Table size={ mobileSize('small') }
        className="main-table"
        style={ { whiteSpace: "nowrap" } }
        loading={ loadingQuery }
        dataSource={ dataQuery && dataQuery.exhibitors }
        rowKey={ item => item.id }
        pagination={ false }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" render={ item => `${ item.id }` } />
        <Column
          className="table-avatar-link"
          title={ <KeyTranslation keyTranslation="table.global.column.head.avatar" defaultValue="Avatar" /> }
          render={ item => {

            return (
              <Link
                to={ {
                  pathname: `/users/id-${ item.id }-${ item.name }-${ item.surname }`.toLowerCase(),
                  state: {
                  }
                } }>
                { item.avatar && <Avatar shape="square" size={ 50 } src={ item.avatar } /> }
                { !item.avatar && <Avatar shape="square" size={ 50 } icon={ <FileImageOutlined /> } /> }
              </Link>
            )
          } } />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.full-name" defaultValue="Full name" /> }
          render={ item => <Link to={ `/users/id-${ item.id }-${ item.name }-${ item.surname }`.toLowerCase() }>{ `${ item.name } ${ item.surname }` }</Link> } />
        <Column
          title={ <KeyTranslation keyTranslation="table.users.column.head.e-mail" defaultValue="E-Mail" /> }
          render={ item => <Link to={ `/users/id-${ item.id }-${ item.name }-${ item.surname }`.toLowerCase() }>{ `${ item.email_original }` }</Link> } />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.role" defaultValue="Role" /> }
          dataIndex="role" key="role" />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.permission" defaultValue="Permission" /> }
          render={
            item =>
              <Radio.Group value={ item.stand_permission.permission }
                onChange={
                  ({ target: { value } }) => {
                    const user_id = item.id;
                    const permission = value;
                    handleRadioChange({ user_id, permission })
                  }
                }>
                <Radio.Button value="owner">Owner</Radio.Button>
                <Radio.Button value="editor">Editor</Radio.Button>
                <Radio.Button value="member">Member</Radio.Button>
              </Radio.Group> }
        />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.visible" defaultValue="Visible" /> }
          render={
            item =>
              <Radio.Group value={ item.stand_permission.visible }
                onChange={
                  ({ target: { value } }) => {
                    const user_id = item.id;
                    const visible = value;
                    handleRadioChange({ user_id, visible })
                  }
                }>
                <Radio.Button value={ true }>Visible</Radio.Button>
                <Radio.Button value={ false }>Invisible</Radio.Button>
              </Radio.Group> }
        />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.action" defaultValue="Action" /> }
          render={ item =>
            <EntityRemoveButton
              nameEntity={ 'Exhibitor' }
              dataNameEntity={ `${ item.name } ${ item.surname }` }
              loading={ loadingMutationDeleteExhibitor }
              deleteMutation={ _setMutationVariableDeleteExhibitor }
              variables={
                {
                  stand_id: dataQuery.id,
                  user_id: item.id
                }
              }
            />
          } />
      </Table>
    </div>
  );
};

export default StandUserTable;