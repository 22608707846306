import React, { useState, cloneElement } from "react";

import { Button, Modal } from "antd";

const EditModal = ({ children, valueBtn = 'Button',
  typeBtn = 'primary',
  titleModal = 'Modal Title',
  modalClassName = 'modal-block',
  disabled = false
}) => {

  const [ modalVisible, setModalVisible ] = useState(false);

  return (
    <>
      <Button className=''
        type={ typeBtn }
        onClick={ () => setModalVisible(true) }
        disabled={ disabled }
      >
        { valueBtn }
      </Button>
      <Modal
        className={ modalClassName }
        title={ titleModal }
        // width="auto"
        centered
        visible={ modalVisible }
        onOk={ () => setModalVisible(false) }
        onCancel={ () => setModalVisible(false) }
        footer={ null }
      >
        { cloneElement(children, { setModalVisible }) }
      </Modal>
    </>
  )
}

export default EditModal;
