import React from "react";
import { Form, Button, Spin, Select, Typography } from "antd";
import { errorNotification } from "../../../result";
import metaFields from '../../../../utils/meta-fields';
import ExbnTaxonomyForm from '../exbn-taxonomy-form';
import ExbnProductsForm from '../exbn-products-form';
import SelectPackages from '../../select-packages';
import { RadioButton, RadioValue } from '../../radio-button';
import KeyTranslation from '../../../service/key-translation';

const { Option } = Select;



const NormalEventSettingsForm = ({
  exbnEvnt,
  loadingQueryEvent,
  setMutationVariableEeMetaSet,
  loadingMutationEeMetaSet
}) => {


  const [ form ] = Form.useForm();


  const { getValue, normalize } = metaFields;
  const meta = normalize(exbnEvnt.meta_fields);
  const {
    setRadioValue: setRadioValueProductActive,
    radioValue: radioValueProductActive,
    defaultRadioVal: initRadioValueProductActive
  } = RadioValue(exbnEvnt, 'product_active');

  const {
    setRadioValue: setRadioValueDownloadActive,
    defaultRadioVal: initRadioValueDownloadActive
  } = RadioValue(exbnEvnt, 'downloads_active');




  const formSubmit = ({ values }) => {

    let { metaValues } = metaFields.parseForm(values);
    metaValues = metaFields.filterEmpty(metaValues, exbnEvnt.meta_fields);

    setMutationVariableEeMetaSet({
      variables: {
        eeMetaSetEeId: exbnEvnt.id,
        eeMetaSetInput: [ ...metaValues ]
      }
    }).catch((error) => {
      errorNotification(error);
    });
  };


  return (
    <div>
      { loadingQueryEvent ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <Form
          form={ form }
          onFinish={ (values) => {
            formSubmit({ values });
          } }
          layout="vertical"
          className="form-container lg settings-form"
        >
          <Typography.Link className="text-decorator">
            <h6><KeyTranslation keyTranslation="heading.small.form.exhibition.exhibition-packages" defaultValue="Exhibition packages" /></h6>
            <div className="separator"></div>
          </Typography.Link>
          <SelectPackages
            initVal={ getValue(meta, "stand_packages") === '' ? [] : getValue(meta, "stand_packages") }
            fieldName={ 'stand_packages-array--ee_packages' }
            selectLabel={ <KeyTranslation keyTranslation="form.exhibition.label.showroom-packages" defaultValue="Showroom packages" /> }
            selectMode={ 'multiple' }
          />

          <ExbnTaxonomyForm
            metaExbnEvent={ exbnEvnt }
          />

          <Typography.Link className="text-decorator">
            <h6><KeyTranslation keyTranslation="heading.small.form.exhibition.exhibition-products" defaultValue="Exhibition products" /></h6>
            <div className="separator"></div>
          </Typography.Link>

          <RadioButton
            radioValue={ initRadioValueProductActive }
            loadingQuery={ loadingQueryEvent }
            radioLabel={ <KeyTranslation keyTranslation="form.exhibition.radio-btn.products-active" defaultValue="Products active" /> }
            radioName={ 'product_active-string--ee_products' }
            radioVal={ [ '1', '0' ] }
            radioText={ [ 'Yes', 'No' ] }
            radioOnChange={ e => setRadioValueProductActive(e.target.value) }
          />

          {
            +radioValueProductActive ? (
              <ExbnProductsForm
                metaExbnEvent={ exbnEvnt }
              />
            ) : ''
          }

          <Typography.Link className="text-decorator">
            <h6><KeyTranslation keyTranslation="heading.small.form.exhibition.exhibition-stands" defaultValue="Exhibition Stands" /></h6>
            <div className="separator"></div>
          </Typography.Link>

          <Form.Item
            name="ee_stand_multiply-string--ee_stands"
            label={ <KeyTranslation keyTranslation="form.exhibition.label.multiply-amount-stands" defaultValue="Multiply amount of stands" /> }
            initialValue={ getValue(meta, "ee_stand_multiply") }
          >
            <Select
              // mode="multiple"
              allowClear
              placeholder="Please select Multiply amount of stands"
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
              <Option value="9">9</Option>
              <Option value="10">10</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="ee_coliseum_row_amount-string--ee_stands"
            label={ <KeyTranslation keyTranslation="form.exhibition.label.amount-coliseum-rows" defaultValue="Amount of coliseum rows" /> }
            initialValue={ getValue(meta, "ee_coliseum_row_amount") }
          >
            <Select
              // mode="multiple"
              allowClear
              placeholder="Please select Amount of coliseum rows"
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
            </Select>
          </Form.Item>

          <Typography.Link className="text-decorator">
            <h6><KeyTranslation keyTranslation="heading.small.form.exhibition.exhibition-downloads" defaultValue="Exhibition downloads" /></h6>
            <div className="separator"></div>
          </Typography.Link>

          <RadioButton
            radioValue={ initRadioValueDownloadActive }
            loadingQuery={ loadingQueryEvent }
            radioLabel={ <KeyTranslation keyTranslation="form.exhibition.radio-btn.downloads-active" defaultValue="Downloads active" /> }
            radioName={ 'downloads_active-string--ee_downloads' }
            radioVal={ [ '1', '0' ] }
            radioText={ [ 'Yes', 'No' ] }
            radioOnChange={ e => setRadioValueDownloadActive(e.target.value) }
          />

          <Form.Item wrapperCol={ { sm: { span: 16, offset: 0 } } } >
            <Button loading={ loadingMutationEeMetaSet } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
          </Form.Item>
        </Form>
      )
      }
    </div>
  );
};

export default NormalEventSettingsForm;
