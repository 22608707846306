const translationArrToObj = (translation_fields = {}) => {

    let translations = {};

    if (translation_fields)
    {
        for (let [ , field ] of Object.entries(translation_fields))
        {
            translations[ field.tr_key ] = field;

        }
    }

    return translations;
};


const getTranslationFieldValue = (translations, fieldName, defaultVal = "") => {
    return translations[ fieldName ] !== undefined ? translations[ fieldName ].value : defaultVal;
};



const translationFields = {
    normalize: translationArrToObj,
    getValue: getTranslationFieldValue
}


export default translationFields;

