import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import convertSortName from '../../../../utils/convert-sort-name';
import filterObj from '../../../../utils/filter-table';
import cache from './../../../../graphql/cache';




const useQueryShowrooms = (query) => {

  let history = useHistory();
  const { eventId, exhibitionEventTab, numPage } = useParams();
  let routePage;

  const [ eeIdColumnFilter, setEeIdColumnFilter ] = useState(undefined);
  const [ packageIdColumnFilter, setPackageIdColumnFilter ] = useState(undefined);



  const exbnEvntFromFilter = eeIdColumnFilter && {
    column: "EE_ID",
    value: +eeIdColumnFilter,
  }


  const packageEvntFromFilter = packageIdColumnFilter && {
    column: "PACKAGE_ID",
    value: +packageIdColumnFilter,
  }


  const exbnEvntStands = eventId && {
    column: "EE_ID",
    value: +eventId,
  }



  if (eventId === undefined)
  {
    routePage = `/showrooms/`;
  } else
  {
    routePage = `/exhibition-events/${ eventId }/${ exhibitionEventTab }/`
  }

  const [ pageSize, setPageSize ] = useState(10);
  const [ searchVal, setSearchVal ] = useState("");
  const [ order, setOrder ] = useState({
    orderBy: [
      {
        column: "ID",
        order: 'ASC'
      }
    ]
  });


  const [ filter, setFilter ] = useState(exbnEvntStands);

  /*eslint-disable */
  useEffect(() =>
    !eventId && setFilter(exbnEvntFromFilter),
    [ eeIdColumnFilter ]
  );


  useEffect(() =>
    !eventId && setFilter(packageEvntFromFilter),
    [ packageIdColumnFilter ]
  );
  /*eslint-enable */



  const variables = {
    ...order,
    where: { ...filter },
    text: searchVal,
    first: pageSize,
    page: +numPage ? +numPage : 1
  }

  const { loading, error, data } = useQuery(query, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const { stands } = !!data && data;
  const { paginatorInfo } = !!data && stands;
  const { total, currentPage } = !!data && paginatorInfo;

  const pagination = {
    current: currentPage,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };


  const handleTableChange = (pagination, filters, sorter, page) => {

    setPageSize(pagination.pageSize)

    const {
      order,
      columnKey
    } = sorter;


    setOrder({
      orderBy: [
        {
          column: !!columnKey ? columnKey.toUpperCase() : 'ID',
          order: !!convertSortName(order) ? convertSortName(order) : 'ASC'
        }
      ]
    });



    if (filterObj(filters) !== {})
    {
      setFilter({
        ...filter,
        AND: [ { ...filterObj(filters) } ]
      })
    } else
    {
      setFilter(exbnEvntStands || exbnEvntFromFilter || packageEvntFromFilter)
    }


    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }page-${ pagination.current }`);
    }
  }

  return {
    eventId,
    setEeIdColumnFilter,
    setPackageIdColumnFilter,
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: stands,
    setSearchVal,
    routePage,
    history,
    pagination,
    handleTableChange,
    rowCount: cache.readQuery({ query, variables })?.stands?.paginatorInfo?.count || 10
  }
};

export default useQueryShowrooms;
