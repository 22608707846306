import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";
import { GET_PACKAGE_RULES } from "../../../../../graphql/query/packages-gql";

const useMutationPackageRuleCreate = (mutation) => {

  const { numPage, pckgId } = useParams();

  const [ _setMutationVariableCreate,
    { loading: loadingMutationCreate,
      error: errorMutationCreate } ] = useMutation(mutation,
        {
          update(_, { data }) {
            const {
              packageRuleCreateOrUpdate: {
                label,
                message
              }
            } = data;

            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },
          refetchQueries: [
            {
              query: GET_PACKAGE_RULES,
              variables: {
                package_id: pckgId,
                first: 30,
                page: +numPage ? +numPage : 1
              },
              // notifyOnNetworkStatusChange: true,
              // fetchPolicy: "cache-and-network",
            },
          ]
        });


  return {
    _setMutationVariableCreate,
    loadingMutationCreate,
    errorMutationCreate,
  }
};

export default useMutationPackageRuleCreate;

