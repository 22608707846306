import React from "react";
import { PackageTabs } from '../components/tabs';




const PackagePage = () => {

  return (
    <div className="page-holder bg-white">
      <PackageTabs />
    </div>
  );
};

export default PackagePage;
