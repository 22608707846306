import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import convertSortName from '../../../../utils/convert-sort-name';
import cache from './../../../../graphql/cache';


const useQueryPackageRequests = (query) => {

  const routePage = `/packages/package-requests/`;


  const [ pageSize, setPageSize ] = useState(10);
  const [ packagesId, setPackageId ] = useState(undefined);

  const [ order, setOrder ] = useState({
    orderBy: [
      {
        column: "ID",
        order: 'ASC'
      }
    ]
  });

  let history = useHistory();
  const { numPage } = useParams();

  const variables = {
    package_id: +packagesId || undefined,
    status: 'waiting',
    ...order,
    first: pageSize,
    page: +numPage ? +numPage : 1,
  };

  const { loading, error, data } = useQuery(query, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });



  const packageRequests = !loading && data?.packageRequests;
  const { paginatorInfo } = !loading && packageRequests;
  const { total, currentPage } = !loading && paginatorInfo;


  const pagination = {
    current: currentPage,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };


  const handleTableChange = (pagination, filters, sorter, page) => {

    setPageSize(pagination.pageSize)

    const {
      order,
      columnKey
    } = sorter;


    setOrder({
      orderBy: [
        {
          column: !!columnKey ? columnKey.toUpperCase() : 'ID',
          order: !!convertSortName(order) ? convertSortName(order) : 'ASC'
        }
      ]
    });


    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }page-${ pagination.current }`);
    }
  }

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: packageRequests.data,
    pagination,
    handleTableChange,
    setPackageId,
    rowCount: cache.readQuery({ query, variables })?.packageRequests?.paginatorInfo?.count || 10
  }
};

export default useQueryPackageRequests;
