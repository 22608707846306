import React from 'react';
import ProfileTabs from '../components/tabs';
// import { UserAddForm } from '../components/forms/user-form/user-form';

const AddUserPage = () => {

    return (
        <div className="page-holder bg-white">
            <ProfileTabs />
        </div>
    );
};

export default AddUserPage;