import React, { useState, useEffect } from "react";
import { useQuery } from '@apollo/client';
import { Select } from "antd";
import { translationInterface } from "../../../graphql/cache";



// Hooks
import useQueryLanguages from './hooks/use-query-languages';

//Query
import { GET_LANGUAGES } from '../../../graphql/query/languages-gql';
import { GET_TRANSLATION_SELECTION } from './../../../graphql/query/languages-gql';

//CSS
import './language-selector.scss';


const { Option } = Select;



const LanguageSelector = ({ getMe }) => {


  const [ defaultLang, setDefaultLang ] = useState(getMe.me.locale)

  const {
    // errorQuery,
    dataQuery,
    loadingQuery,
  } = useQueryLanguages(GET_LANGUAGES);

  const { data: dataQueryTranslation } = useQuery(GET_TRANSLATION_SELECTION, {
    variables: {
      first: 9999,
      where: {
        column: "TYPE",
        value: "admin",
        AND: [ { column: "LOCALE", value: defaultLang } ]
      }
    },
  });


  // SET dataQueryTranslation to reactive variable
  useEffect(() => {
    translationInterface(dataQueryTranslation)
  }, [ dataQueryTranslation ]);



  return (

    <Select
      defaultValue={ defaultLang }
      loading={ loadingQuery }
      style={ { width: '70px', marginLeft: '5px' } }
      onChange={ (value) => {
        setDefaultLang(value);
      } }
    >
      { dataQuery && dataQuery.map((item) => {
        return (
          <Option key={ item.id } value={ item.slug }>
            <span>{ item.slug }</span>
          </Option>
        )
      }) }
    </Select>

  )
};

export default LanguageSelector;
