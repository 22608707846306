import React from "react";
import placeholder from '../assets/placeholder-download.png'
import KeyTranslation from '../components/service/key-translation';

const Dashboard = () => {
  return (
    <div className="page-holder">
      <h1>
        <KeyTranslation
          keyTranslation="heading.large.dashboard"
          defaultValue="Dashboard" />
      </h1>

      <img src={ placeholder } alt="placeholder dashboard" />
    </div>
  );
};

export default Dashboard;