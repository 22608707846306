import React from "react";
import { useQuery } from "@apollo/client";
import { Result, Spin, Button } from "antd";
import MainLayout from "../main-layout";
import { isLoggedInVar } from 'graphql/cache';
import { GET_ME } from '../../graphql/query/user-gql';


const App = () => {


  const { loading, error, data } = useQuery(GET_ME);


  if (error)
  {
    return (
      <Result
        status="500"
        title="ERROR"
        subTitle="Sorry something went wrong. Please try to reload page or contact with administrator."
        extra={
          <Button
            type="primary"
            onClick={ () => {
              localStorage.clear();
              isLoggedInVar(false);
            } }
          >
            Back Home
          </Button>
        }
      />
    );
  }
  return (
    <>
      { loading ? (
        <div className="block-loader h-100vh">
          <Spin size="large" tip="Loading..." />
        </div>
      ) : (
        <MainLayout getMe={ data } />
      ) }
    </>
  );
}

export default App;
