import React from "react";
import NormalPackageRuleCreateForm from './normal-package-rule-create-form';
import useMutationPackageRuleCreate from './hooks/use-mutation-package-rule-create';
import useQueryPackageRuleConstants from './hooks/use-query-package-rule-constants';

import { GET_PACKAGE_RULE_CONSTANTS } from '../../../../graphql/query/packages-gql';
import { PACKAGE_RULE_CREATE_UPDATE } from '../../../../graphql/mutation/packages-gql'


import './package-rule-create-form.scss';




const PackageRuleCreateForm = ({ dataQuery, loadingQuery, setModalVisible }) => {


  const {
    _setMutationVariableCreate,
    loadingMutationCreate,

  } = useMutationPackageRuleCreate(PACKAGE_RULE_CREATE_UPDATE);

  const {
    loadingQueryRuleConstants,
    errorQueryRuleConstants,
    dataQueryRuleConstants
  } = useQueryPackageRuleConstants(GET_PACKAGE_RULE_CONSTANTS);


  return (
    <NormalPackageRuleCreateForm
      dataQuery={ dataQuery }
      loadingQuery={ loadingQuery }
      loadingMutationCreate={ loadingMutationCreate }
      setMutationVariableCreate={ _setMutationVariableCreate }
      setModalVisible={ setModalVisible }
      loadingQueryRuleConstants={ loadingQueryRuleConstants }
      errorQueryRuleConstants={ errorQueryRuleConstants }
      dataQueryRuleConstants={ dataQueryRuleConstants }
    />
  );
};


export default PackageRuleCreateForm;
