import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Tabs, Typography } from "antd";
import AccountTabSettings from './tabs/account-settings-tab';
import ProfileTab from './tabs/profile-tab';
import ProfileTabChangePassword from './tabs/profile-change-password-tab.js';
import KeyTranslation from '../../service/key-translation';
import mobileSize from '../../../utils/mobile-size';
import { GET_USER } from './../../../graphql/query/user-gql';

import './profile-tabs.scss';


const ProfileTabs = () => {

    const { id, profileTab } = useParams();
    let history = useHistory();

    const { data, loading, error } = useQuery(GET_USER, {
        skip: !id,
        variables: {
            userAdmId: id
        },
        // fetchPolicy: "cache-and-network",
    });


    const user = !!data && (data.user)
    const fullName = `${ user.name }-${ user.surname }`.toLowerCase()

    const { TabPane } = Tabs;

    return (
        <div className="tabs-form profile-tabs">

            <h3>
                {
                    !!user ?
                        <><KeyTranslation keyTranslation="heading.middle.user.edit-account" defaultValue="Edit account" /> <Typography.Link>{ user.name } { user.surname }</Typography.Link></> :
                        <KeyTranslation keyTranslation="heading.middle.user.add-user" defaultValue="Add user" />
                }
            </h3>


            <Tabs
                type="card"
                tabPosition={ mobileSize(true, 1300) ? 'top' : 'left' }
                activeKey={ profileTab ? profileTab : 'account-settings' }
                onTabClick={ (key) => {
                    changeTab(history, key, id, fullName);
                } }
            >
                <TabPane tab={ <KeyTranslation keyTranslation="tab.user.account-settings" defaultValue="Account Settings" /> } key="account-settings">
                    <AccountTabSettings
                        userId={ id }
                        getUser={ user }
                        loadingUser={ loading }
                        errorUser={ error }
                    />
                </TabPane>
                { !!id && !error && (
                    <>
                        <TabPane tab={ <KeyTranslation keyTranslation="tab.user.profile" defaultValue="Profile" /> } key="profile">
                            <ProfileTab getUser={ user } loadingUser={ loading } />
                        </TabPane>

                        <TabPane tab={ <KeyTranslation keyTranslation="tab.user.change-password" defaultValue="Change Password" /> } key="change-password">
                            <ProfileTabChangePassword getUser={ user } />
                        </TabPane>
                    </>
                ) }
            </Tabs>
        </div>
    );
};


export default ProfileTabs;


//Pure functions

const changeTab = (history, tabKey, id, fullName) => {

    if (!!id)
    {
        if (tabKey === 'account-settings')
        {
            history.push(`/users/id-${ id }-${ fullName }/`);
        } else
        {
            history.push(`/users/id-${ id }-${ fullName }/${ tabKey }/`);
        }
    } else
    {
        history.push(`/users/add-user`);
    }
};
