import React, { useState } from "react";
import { Form, Button, Input, Select, Spin, DatePicker } from "antd";
import { errorNotification } from "../../../result";
import moment from 'moment';
import AvatarUpload from './../../../upload/avatar/avatar-upload/index';
import useMutationMetaEeUpload from '../hooks/use-mutation-exbn-evnt-meta-upload';
import metaFields from '../../../../utils/meta-fields';
import KeyTranslation from '../../../service/key-translation';

import { EXBN_EVNT_META_UPLOAD } from "../../../../graphql/mutation/exhibition-gql";

const NormalEventForm = ({
  exbnEvnt,
  setMutationVariableCreate,
  setMutationVariableUpdate,
  loadingQueryEvent,
  loadingMutationCreate,
  loadingMutationUpdate
}) => {

  const [ form ] = Form.useForm();
  const { Option } = Select;


  const {
    _setMutationVariableEeUpload,
    loadingMutationEeUpload,

  } = useMutationMetaEeUpload(EXBN_EVNT_META_UPLOAD, exbnEvnt, form);


  const { getValue, normalize } = metaFields;
  const meta = normalize(exbnEvnt.meta_fields);


  const defaultStartDate = !exbnEvnt ? moment(exbnEvnt).isValid() : moment(exbnEvnt.start_at).isValid() && moment(exbnEvnt.start_at);
  const defaultEndDate = !exbnEvnt ? moment(exbnEvnt).isValid() : moment(exbnEvnt.end_at).isValid() && moment(exbnEvnt.end_at);
  const defaultArchvDate = !exbnEvnt ? moment(exbnEvnt).isValid() : moment(exbnEvnt.archive_at).isValid() && moment(exbnEvnt.archive_at);

  const [ evntStart, setEvntStart ] = useState('');
  const [ evntEnd, setEvntEnd ] = useState('');
  const [ evntArchv, setEventArchv ] = useState('');

  const loadingMutation = !exbnEvnt.id ?
    loadingMutationCreate :
    loadingMutationUpdate;


  const formSubmit = ({ values, setMutationVariableCreate }) => {

    const createUpdateFields = {
      status: values.status,
      title: values.title,
      uri: values.url,
      start_at: !evntStart ? exbnEvnt.start_at : evntStart,
      end_at: !evntEnd ? exbnEvnt.start_at : evntEnd,
      archive_at: !evntArchv ? exbnEvnt.archive_at : evntArchv,
    }

    if (!exbnEvnt.id)
    {
      setMutationVariableCreate({
        variables: {
          eeCreateInput: {
            ...createUpdateFields
          }
        }
      }).catch((errorMutation) => {
        errorNotification(errorMutation);
      });

    } else
    {
      setMutationVariableUpdate({
        variables: {
          eeUpdateEeId: exbnEvnt.id,
          eeUpdateInput: {
            ...createUpdateFields
          }
        }
      }).catch((errorMutation) => {
        errorNotification(errorMutation);
      });
    }
  };

  const buttonValue = !exbnEvnt.id ? 'Create Event' : 'Update Event';

  const wrappСonditions = (
    <>
      <Form.Item
        name="title"
        label={ <KeyTranslation keyTranslation="form.global.label.title" defaultValue="Title" /> }
        rules={ [
          {
            type: "string"

          },
          { required: true, message: 'Please input Title' }
        ] }
      >
        <Input placeholder="Enter your Event title" />
      </Form.Item>

      <Form.Item
        name="url"
        label={ <KeyTranslation keyTranslation="form.global.label.website" defaultValue="Website" /> }
        rules={ [
          {
            type: "string",
          },
          { required: true, message: 'Please input url your website' }
        ] }
      >
        <Input />
      </Form.Item>
    </>
  );

  return (
    <div>
      { loadingQueryEvent ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <Form
          className="form-container lg"
          initialValues={ {
            url: exbnEvnt.uri,
            status: exbnEvnt.status,
            title: exbnEvnt.title,
          } }
          form={ form }
          onFinish={ (values) => {
            formSubmit({ values, setMutationVariableCreate });
          } }
          layout="vertical"
        >
          <Form.Item className="form-group">

            { !!exbnEvnt.id && (
              <Form.Item
                label={ <KeyTranslation keyTranslation="form.global.label.logo" defaultValue="Logo" /> }
                name="ee_logo"
              // rules={ [ ...avatarRules ] }
              >
                <AvatarUpload
                  size={ 140 }
                  uploadMutation={ _setMutationVariableEeUpload }
                  image={ getValue(meta, "ee_logo") }
                  loading={ loadingMutationEeUpload }
                  variables={
                    {
                      ee_id: exbnEvnt.id,
                      meta_key: "ee_logo",
                    }
                  }
                  extraClass={ "contain" }
                  uploadButtonText={ <KeyTranslation keyTranslation="button.global.upload-logo" defaultValue="Upload Logo" /> }
                  shape={ "square" }
                />
              </Form.Item>
            ) }
            {
              !!exbnEvnt
                ?
                <div>
                  { wrappСonditions }
                </div>
                :
                wrappСonditions
            }
            <Form.Item
              name="start_at"
              label={ <KeyTranslation keyTranslation="form.global.label.start-date" defaultValue="Start date" /> }
            >
              <DatePicker style={ { width: '100%' } }
                placeholder="Start date"
                disabledDate={ disabledDate }
                defaultValue={ defaultStartDate }
                showTime format="YYYY-MM-DD HH:mm:00"
                onChange={ (value, dateString) => setEvntStart(dateString) } />
            </Form.Item>
            <Form.Item
              name="end_at"
              label={ <KeyTranslation keyTranslation="form.global.label.end-date" defaultValue="End date" /> }
            >
              <DatePicker style={ { width: '100%' } }
                placeholder="End date"
                disabledDate={ disabledDate }
                defaultValue={ defaultEndDate }
                showTime format="YYYY-MM-DD HH:mm:00"
                onChange={ (value, dateString) => setEvntEnd(dateString) } />
            </Form.Item>


            <Form.Item
              name="status"
              label={ <KeyTranslation keyTranslation="form.global.label.status" defaultValue="Status" /> }
              rules={ [ { required: true, message: 'Please select Status' } ] }
            >
              <Select placeholder="Select your Status">
                <Option value="new">new</Option>
                <Option value="available">available</Option>
                <Option value="active">active</Option>
                <Option value="close">close</Option>
                <Option value="archive">archive</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="archive_at"
              label={ <KeyTranslation keyTranslation="form.global.label.archive-date" defaultValue="Archive date" /> }
            >
              <DatePicker style={ { width: '100%' } }
                placeholder="Archive date"
                disabledDate={ disabledDate }
                defaultValue={ defaultArchvDate }
                showTime format="YYYY-MM-DD HH:mm:00"
                onChange={ (value, dateString) => setEventArchv(dateString) } />
            </Form.Item>
          </Form.Item>

          <Form.Item wrapperCol={ { sm: { span: 16, offset: 0 } } } >
            <Button loading={ loadingMutation } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>{ buttonValue }</Button>
          </Form.Item>
        </Form>
      )
      }
    </div>
  );
}

export default NormalEventForm;



// Pure Function

function disabledDate(current) {
  return current && current < moment().endOf('day').add(-1, 'days');
}