import { useState } from "react";
import { useEventListener } from './use-event-listener';


export const useOnEventNode = ({
    delegationSelector,
    targetSelector,
    eventName,
    callback }) => {
    const [ targetEl, setTargetEl ] = useState(false);

    let delegationNode = document.querySelector(delegationSelector);

    useEventListener(eventName, (e) => {
        const targetNode = e.target.closest(targetSelector);
        if (!targetNode) return;
        if (!delegationNode.contains(targetNode)) return;
        callback();
        setTargetEl(targetNode);
    }, delegationNode);

    return targetEl;
};


/*
Usage
  useOnEventNode({
    delegationSelector: '#main-nav',
    targetSelector: '.ant-menu-item-only-child',
    eventName: 'click',
    callback: toggle
  });
*/