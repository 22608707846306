import React from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Tabs, Collapse, Button, Typography } from "antd";
import CompanyProfileForm from '../../forms/showroom-forms/company-profile-form';
import CompanyPageForm from '../../forms/showroom-forms/company-page-form';
import CompanyProductsForm from '../../forms/showroom-forms/company-products-form';
import CompanyDownloadForm from '../../forms/showroom-forms/company-download-form';
import StandUser from '../../showrooms/stand-users';
import StandProducts from '../../showrooms/stand-products/stand-products';
import CompanyCommunicationPageForm from '../../forms/showroom-forms/company-communication-form';
import PackageRequestsStand from '../../packages/package-requests-stand/package-requests-stand';
import packageRules from './../../../utils/package-rules';
import StandUploadFile from '../../showrooms/stand-upload-file';
import StandDownloads from '../../showrooms/stand-downloads';
import KeyTranslation from '../../service/key-translation';
import mobileSize from '../../../utils/mobile-size';

import { GET_STAND } from './../../../graphql/query/showrooms-gql';


import './showroom-tabs.scss';







const { Panel } = Collapse;
const { TabPane } = Tabs;




const SettingsPanel = ({ stand, standId, loading }) => {


    const {
        permittedNumbersStand,
        activePackageRule,
        disabledEntityFromRules
    } = Rules(stand);


    return (
        <Collapse>
            <Panel
                header={ <KeyTranslation keyTranslation="button.global.page-settings" defaultValue="Page settings" /> }
                key="1"
                extra={
                    <div className='interface-block'>
                        { stand.package?.package_rules ?
                            (
                                (permittedNumbersStand || !activePackageRule) ?
                                    <span>
                                        { permittedNumbersStand && 'Maximum number of "Product" reached of this package' }
                                        { (permittedNumbersStand && !activePackageRule) && ' and also ' }
                                        { !activePackageRule && 'This action is prohibited in the rules of this package:' }

                                        <Link
                                            className="form-link"
                                            to={ {
                                                pathname: `/packages/${ stand.package?.id }/rules`,
                                                state: {
                                                    breadcrumbSlug: stand.package?.title,
                                                }
                                            } }
                                        >
                                            <Typography.Link>
                                                <strong> "{ stand.package?.title }" </strong>
                                            </Typography.Link>
                                        </Link>
                                    </span> : ''
                            )
                            : 'No tariff package is selected for this showroom. You cannot add product.'

                        }
                        <Link
                            style={ { position: 'absolute', bottom: '7px', right: '10px' } }
                            to={ {
                                pathname: `/products/add-product/${ standId }`,
                                state: {
                                    stand_id: standId,
                                    stand_title: stand.title
                                }
                            } }>
                            {/* <Button disabled={ disabledEntityFromRules } type="primary">Add product</Button> */ }
                            <Button disabled={ disabledEntityFromRules } type="primary">
                                <KeyTranslation keyTranslation="button.global.add-product" defaultValue="Add product" />
                            </Button>
                        </Link>
                    </div>
                }
            >
                <CompanyProductsForm
                    dataQueryStand={ stand }
                    loadingQueryStand={ loading } />
            </Panel>
        </Collapse>
    )
}


const UploadBtnRules = ({ stand }) => {


    //Package rules for stand product
    const { packageRuleForStand } = packageRules;


    const {
        permittedNumbersStand,
        activePackageRule,
        disabledEntityFromRules } = packageRuleForStand(stand, 'stand_document_amount');


    return (
        <div className="download-box"
            onClick={ event => { event.stopPropagation() } }
        >
            <span className="text">
                { stand.package?.package_rules ?
                    (
                        (permittedNumbersStand || !activePackageRule) ?
                            <span>
                                { permittedNumbersStand && 'Maximum number of "Attachments" reached of this package' }
                                { (permittedNumbersStand && !activePackageRule) && ' and also ' }
                                { !activePackageRule && 'This action is prohibited in the rules of this package:' }

                                <Link
                                    className="form-link"
                                    to={ {
                                        pathname: `/packages/${ stand.package?.id }/rules`,
                                        state: {
                                            breadcrumbSlug: stand.package?.title,
                                        }
                                    } }
                                >
                                    <Typography.Link>
                                        <strong> "{ stand.package?.title }" </strong>
                                    </Typography.Link>
                                </Link>
                            </span> : ''
                    )
                    : 'No tariff package is selected for this showroom. You cannot add Attachments.'

                }
            </span>
            <StandUploadFile dataQuery={ stand } disabledBtn={ disabledEntityFromRules } />
        </div>
    )
}



const ShowroomTabs = () => {

    const { standId, standTab } = useParams();
    let history = useHistory();

    const { data, loading } = useQuery(GET_STAND, {
        skip: !standId,
        variables: {
            standId
        },
        // notifyOnNetworkStatusChange: true,
        // fetchPolicy: "no-cache",
    });


    const { stand = false } = data !== undefined ? data : {};


    return (
        <div className="tabs-form showroom-tabs">
            {
                !loading && (!standId ?

                    <h3><KeyTranslation keyTranslation="heading.middle.showroom.add-showroom" defaultValue="Add Showroom" /></h3> :
                    <h3><KeyTranslation keyTranslation="heading.middle.showroom.edit-showroom" defaultValue="Edit Showroom" /> <Typography.Link>{ stand.title }</Typography.Link></h3>
                )
            }

            <Tabs
                type="card"
                tabPosition={ mobileSize(true, 1300) ? 'top' : 'left' }
                activeKey={ standTab ? standTab : 'company-profile' }
                onTabClick={ (key) => {
                    changeTab(history, key, standId);
                } }
            >
                <TabPane tab={ <KeyTranslation keyTranslation="tab.showroom.company-profile" defaultValue="Company Profile" /> } key="company-profile">
                    <CompanyProfileForm
                        dataQueryStand={ stand }
                        loadingQueryStand={ loading } />
                </TabPane>
                { !!standId && (
                    <>
                        <TabPane tab={ <KeyTranslation keyTranslation="tab.showroom.company-page" defaultValue="Company page" /> } key="company-page">
                            <CompanyPageForm
                                dataQueryStand={ stand }
                                loadingQueryStand={ loading } />
                        </TabPane>

                        <TabPane tab={ <KeyTranslation keyTranslation="tab.showroom.products" defaultValue="Products" /> } key="products">
                            <>
                                <SettingsPanel
                                    stand={ stand }
                                    standId={ standId }
                                    loading={ loading } />
                                <br />
                                <StandProducts />
                            </>
                        </TabPane>

                        <TabPane tab={ <KeyTranslation keyTranslation="tab.showroom.сommunication-page" defaultValue="Communication page" /> } key="сommunication-page">
                            <CompanyCommunicationPageForm
                                dataQueryStand={ stand }
                                loadingQueryStand={ loading } />
                        </TabPane>

                        <TabPane tab={ <KeyTranslation keyTranslation="tab.showroom.download-page" defaultValue="Download page" /> } key="download-page">
                            <div className="download-block upload-file-list-hidden" style={ { position: 'relative' } }>
                                <Collapse>

                                    <Panel
                                        header={ <KeyTranslation keyTranslation="button.global.page-settings" defaultValue="Page settings" /> }
                                        key="1"
                                        extra={
                                            <UploadBtnRules stand={ stand } />
                                        }
                                    >
                                        <CompanyDownloadForm
                                            dataQueryStand={ stand }
                                            loadingQueryStand={ loading } />
                                    </Panel>
                                </Collapse>
                                <br />
                                <StandDownloads standId={ stand?.id } />
                            </div>
                        </TabPane>

                        <TabPane tab={ <KeyTranslation keyTranslation="tab.showroom.company-team" defaultValue="Company Team" /> } key="company-team">
                            <StandUser
                                dataQueryStand={ stand }
                                loadingQuery={ loading } />
                        </TabPane>

                        <TabPane tab={ <KeyTranslation keyTranslation="tab.showroom.package" defaultValue="Package" /> } key="package">
                            <PackageRequestsStand
                                dataQuery={ stand }
                                loadingQuery={ loading } />
                        </TabPane>
                    </>
                ) }
            </Tabs>
        </div>
    );
};


export default ShowroomTabs;


//Pure functions

const changeTab = (history, tabKey, standId) => {

    if (!!standId)
    {
        if (tabKey === 'company-profile')
        {
            history.push(`/showrooms/${ standId }`);
        } else
        {
            history.push(`/showrooms/${ standId }/${ tabKey }`);
        }
    } else
    {
        history.push(`/showrooms/add-showroom`);
    }
};


const Rules = (stand) => {


    //Package rules for stand product
    const { packageRuleForStand } = packageRules;


    const x = (ruleKey) => {

        const {
            permittedNumbersStand,
            activePackageRule,
            disabledEntityFromRules,
            disabledEntityFromRules: conditionRule
        } = packageRuleForStand(stand, ruleKey);


        return {
            permittedNumbersStand,
            activePackageRule,
            disabledEntityFromRules,
            conditionRule
        }
    };


    const { conditionRule: productAmount } = x('product_amount');
    const { conditionRule: productAmountAuthor } = x('product_amount_author');


    if (productAmount)
    {
        return x('product_amount_author')
    }


    if (productAmountAuthor)
    {
        return x('product_amount')
    }


    if (!productAmount && !productAmountAuthor)
    {
        return x('product_amount')
    }

}
