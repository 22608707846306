import React from 'react';

import ProfileTabs from '../components/tabs';


const ProfilePage = () => {

    return (
        <div className="page-holder bg-white">
            <ProfileTabs />
        </div>
    );
};

export default ProfilePage;
