import { useWindowSize } from '../components/hooks/use-window-size'

const MobileSize = (param, screenResolution = 1500) => {

    const displayWidth = useWindowSize();

    if (displayWidth.width < screenResolution)
    {
        return param;
    }
};

export default MobileSize;