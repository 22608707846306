import React from "react";
import { Link } from "react-router-dom";
import { Table, Typography, Button } from "antd";
import mobileSize from "../../../../utils/mobile-size";
import SelectExbnEvnt from '../../../forms/select-exbn-evnt';
import TableColumnSelectFilter from '../../../service/table-column-select-filter';
import KeyTranslation from '../../../service/key-translation';
import SkeletonTable from '../../../service/skeleton-table';
import "./packages-table.scss"




const { Column } = Table;
const { Text } = Typography;





const PackagesTable = ({
  dataQuery,
  loadingQuery,
  pagination,
  handleTableChange,
  setExhibitionEventId,
  rowCount }) => {


  return (
    <div className="holder-table packages-table">
      <Table size={ mobileSize('small') }
        className="main-table"
        tableLayout={ mobileSize(true, 1300) ? '' : 'fixed' }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        loading={ { spinning: loadingQuery, indicator: <SkeletonTable rowCount={ rowCount } columns={ "".split.call(Array(10), ",") } /> } }
        dataSource={ dataQuery }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column
          className="column-width-70"
          title="ID"
          sorter="true" dataIndex="id" key="id" />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.title" defaultValue="Title" /> }
          key="title" render={
            item => !item.title ?
              <Link
                to={ {
                  pathname: `/packages/${ item.id }`,
                  state: {
                    breadcrumbSlug: 'Product name is not filled'
                  }
                } }
              >
                <Text type="secondary">Product name is not filled</Text>
              </Link> :
              <Link
                to={ {
                  pathname: `/packages/${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }
              >
                { `${ item.title }` }
              </Link> } />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.description" defaultValue="Description" /> }
          dataIndex="description" key="description" />
        <Column
          title={ <KeyTranslation keyTranslation="table.packages.column.head.price" defaultValue="Price" /> }
          dataIndex="price" key="price" />

        { TableColumnSelectFilter({
          setFilter: setExhibitionEventId,
          columnTitle: <KeyTranslation keyTranslation="table.global.column.head.exhibition-event" defaultValue="Exhibition event" />,
          columnKey: 'Exhibition event',
          columnSelectFilter: <SelectExbnEvnt />,
          columnRender: item =>
            <Link
              to={ {
                pathname: `exhibition-events/${ item.exhibition_event?.id }`,
                state: {
                  breadcrumbSlug: item.exhibition_event?.title
                }
              } }
            >
              { `${ item.exhibition_event?.title }` }
            </Link>
          ,
          columnFormOnFinish: (values, setFilter) => {
            setFilter(values.exhibitionEvent)
          },
        })
        }

        <Column title="PDF" render={ item => !item.pdf ? 'No' : 'Yes' } />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.created" defaultValue="Created" /> }
          sorter="true" dataIndex="created_at" key="created_at" />
        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.action" defaultValue="Action" /> }
          render={ item =>
            <Link
              to={ {
                pathname: `/packages/${ item.id }`,
                state: {
                  breadcrumbSlug: item.title
                }
              } }
            >
              <Button type="primary">Edit</Button>
            </Link> } />
      </Table>
    </div>
  );
};

export default PackagesTable;

