import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";
import { GET_TRANSLATIONS } from "../../../../../graphql/query/languages-gql";


const useMutationTranslationUpdate = (mutation) => {

  const [ _setMutationVariableCreate,
    { loading: loadingMutationCreate,
      error: errorMutationCreate } ] = useMutation(mutation,
        {
          update(cache, { data }) {
            const {
              translationUpdate: {
                label,
                message
              }
            } = data;

            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },

          refetchQueries: [
            {
              query: GET_TRANSLATIONS,
            },
          ]

        });

  return {
    _setMutationVariableCreate,
    loadingMutationCreate,
    errorMutationCreate,
  }
};

export default useMutationTranslationUpdate;

