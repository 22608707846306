import { gql } from "@apollo/client";


export const GET_EXBN_EVNT_ALL = gql`
    query GetExbnEvntAll($first: Int, $page: Int) {
        eeAll(first: $first, page: $page) {
            data {
                id
                title
                uri
                start_at
                end_at
                status
                archive_at
                created_at
                meta_fields {
                    meta_key
                    meta_value
                }
            }
            paginatorInfo {
                count
                total
                perPage
                lastPage
                currentPage
            }
        }
    }
`;

export const GET_EXBN_EVNT = gql`
    query GetExbnEvnt($eventId: ID) {
        exhibition_event(id: $eventId) {
        id
        title
        uri
        start_at
        end_at
        archive_at
        status
        meta_fields {
            id
            ee_id
            meta_key
            meta_value
            meta_group
          }
        }
    }
`;


