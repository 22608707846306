import React from "react";
import { Form, Spin, Button, Typography } from "antd";
import useMutationMetaEeUpload from '../hooks/use-mutation-exbn-evnt-meta-upload';
import metaFields from '../../../../utils/meta-fields';
import AvatarUpload from '../../../upload/avatar/avatar-upload';
import ChoiceColor from './../../../choice-color/index';
import { RadioButton, RadioValue } from '../../radio-button';
import { errorNotification } from "../../../result";
import KeyTranslation from '../../../service/key-translation';

import { EXBN_EVNT_META_UPLOAD } from "../../../../graphql/mutation/exhibition-gql";




const NormalEventThemeForm = ({
  exbnEvnt,
  loadingQueryEvent,
  setMutationVariableEeMetaSet,
  loadingMutationEeMetaSet
}) => {


  const [ form ] = Form.useForm();

  const {
    _setMutationVariableEeUpload,
    loadingMutationEeUpload,

  } = useMutationMetaEeUpload(EXBN_EVNT_META_UPLOAD, exbnEvnt, form);


  const { getValue, normalize } = metaFields;
  const meta = normalize(exbnEvnt.meta_fields);

  const { setRadioValue: setRadioValueInfo, defaultRadioVal: initRadioValueInfo } = RadioValue(exbnEvnt, 'ee_info_block');
  const { setRadioValue: setRadioValueShadow, defaultRadioVal: initRadioValueShadow } = RadioValue(exbnEvnt, 'ee_login_form_shadow');
  const { setRadioValue: setRadioValueMain, radioValue: radioValueMain, defaultRadioVal: initRadioValueMain } = RadioValue(exbnEvnt, 'ee_main_bg_type', 'color', loadingQueryEvent);



  const formSubmit = ({ values }) => {

    let { metaValues } = metaFields.parseForm(values);
    metaValues = metaFields.filterEmpty(metaValues, exbnEvnt.meta_fields);

    setMutationVariableEeMetaSet({
      variables: {
        eeMetaSetEeId: exbnEvnt.id,
        eeMetaSetInput: [ ...metaValues ]
      }
    }).catch((error) => {
      errorNotification(error);
    });
  };


  return (
    <div>
      { loadingQueryEvent ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <Form
          form={ form }
          onFinish={ (values) => {
            formSubmit({ values });
          } }
          layout="vertical"
          className="form-container lg form-theme"
        >

          <Form.Item
            label={ <KeyTranslation keyTranslation="form.global.label.primary-color" defaultValue="Primary color" /> }
          >
            <ChoiceColor
              defaultColor={ getValue(meta, "primary_color") }
              uploadMutation={ setMutationVariableEeMetaSet }
              loadingMutationStandMetaSet={ loadingMutationEeMetaSet }
              btnText={ <KeyTranslation keyTranslation="button.global.choose-primary-color" defaultValue="Choose Primary color" /> }
              variables={
                {
                  eeMetaSetEeId: exbnEvnt.id,
                  eeMetaSetInput: [ {
                    meta_key: "primary_color",
                    meta_group: "ee_theme",
                    meta_type: 'string'
                  } ]
                }
              }
            />
          </Form.Item>


          <div className="row-grid md col-md-2">
            <Form.Item
              className="image-page-cutomization"
              initialValue={ getValue(meta, "ee_alt_logo") }
              label={ <KeyTranslation keyTranslation="form.global.label.alternative-logo" defaultValue="Alternative Logo" /> }
              name="ee_alt_logo-image-unset"
            >
              <AvatarUpload
                uploadMutation={ _setMutationVariableEeUpload }
                image={ getValue(meta, "ee_alt_logo") }
                loading={ loadingMutationEeUpload }
                variables={
                  {
                    ee_id: exbnEvnt.id,
                    meta_key: "ee_alt_logo",
                    meta_type: 'image',
                    meta_group: "ee_theme",
                  }
                }
                extraClass={ "contain" }
                uploadButtonText={ <KeyTranslation keyTranslation="button.global.upload-image" defaultValue="Upload Image" /> }
                shape={ "square" }
              />
            </Form.Item>
          </div>

          <Typography.Link className="text-decorator">
            <h6><KeyTranslation keyTranslation="heading.small.form.exhibition.exhibition-login" defaultValue="Exhibition Login" /></h6>
            <div className="separator"></div>
          </Typography.Link>

          <Form.Item
            className="image-page-cutomization no-height"
            initialValue={ getValue(meta, "ee_login_bg") }
            label={ <KeyTranslation keyTranslation="form.exhibition.label.login-background-image" defaultValue="Login background image" /> }
            name="ee_login_bg-image-unset"
          >
            <AvatarUpload
              uploadMutation={ _setMutationVariableEeUpload }
              image={ getValue(meta, "ee_login_bg") }
              loading={ loadingMutationEeUpload }
              variables={
                {
                  ee_id: exbnEvnt.id,
                  meta_key: "ee_login_bg",
                  meta_type: 'image',
                  meta_group: "ee_theme",
                }
              }
              uploadButtonText={ <KeyTranslation keyTranslation="button.global.upload-image" defaultValue="Upload Image" /> }
              shape={ "square" }
            />
          </Form.Item>


          <RadioButton
            radioValue={ initRadioValueInfo }
            loadingQuery={ loadingQueryEvent }
            radioLabel={ <KeyTranslation keyTranslation="form.exhibition.radio-btn.show-info-block" defaultValue="Show info block" /> }
            radioName={ 'ee_info_block-string--ee_theme' }
            radioVal={ [ '1', '0' ] }
            radioText={ [ 'Yes', 'No' ] }
            radioOnChange={ e => setRadioValueInfo(e.target.value) }
          />


          <Form.Item
            label={ <KeyTranslation keyTranslation="form.exhibition.label.Info-block-background-color" defaultValue="Info block background color" /> }
          >
            <ChoiceColor
              defaultColor={ getValue(meta, "info_block_bg_color") }
              uploadMutation={ setMutationVariableEeMetaSet }
              loadingMutationStandMetaSet={ loadingMutationEeMetaSet }
              btnText={ <KeyTranslation keyTranslation="button.global.choose-info-block-background-color" defaultValue="Choose Info block background color" /> }
              variables={
                {
                  eeMetaSetEeId: exbnEvnt.id,
                  eeMetaSetInput: [ {
                    meta_key: "info_block_bg_color",
                    meta_group: "ee_theme",
                    meta_type: 'string'
                  } ]
                }
              }
            />
          </Form.Item>

          <RadioButton
            radioValue={ initRadioValueShadow }
            loadingQuery={ loadingQueryEvent }
            radioLabel={ <KeyTranslation keyTranslation="form.exhibition.radio-btn.form-shadow" defaultValue="Form shadow" /> }
            radioName={ 'ee_login_form_shadow-string--ee_theme' }
            radioVal={ [ '1', '0' ] }
            radioText={ [ 'Yes', 'No' ] }
            radioOnChange={ e => setRadioValueShadow(e.target.value) }
          />

          <Typography.Link className="text-decorator">
            <h6><KeyTranslation keyTranslation="heading.small.form.exhibition.exhibition-main" defaultValue="Exhibition Main" /></h6>
            <div className="separator"></div>
          </Typography.Link>

          <RadioButton
            radioValue={ initRadioValueMain }
            loadingQuery={ loadingQueryEvent }
            radioLabel={ <KeyTranslation keyTranslation="form.exhibition.radio-btn.exhibition-main-background" defaultValue="Exhibition main background" /> }
            metaKey={ 'ee_main_bg_type' }
            radioName={ 'ee_main_bg_type-string--ee_theme' }
            radioVal={ [ 'color', 'image' ] }
            radioText={ [ 'Color', 'Image' ] }
            radioOnChange={ e => setRadioValueMain(e.target.value) }
          />

          <Form.Item className="form-group">
            <Form.Item
              label={ <KeyTranslation keyTranslation="form.global.label.main-background-color" defaultValue="Main background color" /> }
            >
              <ChoiceColor
                defaultColor={ getValue(meta, "main_bg_color") }
                uploadMutation={ setMutationVariableEeMetaSet }
                loadingMutationStandMetaSet={ loadingMutationEeMetaSet }
                btnText={ <KeyTranslation keyTranslation="button.global.choose-main-background-color" defaultValue="Choose Main background color" /> }
                variables={
                  {
                    eeMetaSetEeId: exbnEvnt.id,
                    eeMetaSetInput: [ {
                      meta_key: "main_bg_color",
                      meta_group: "ee_theme",
                      meta_type: 'string'
                    } ]
                  }
                }
              />
            </Form.Item>

            <Form.Item
              label={ <KeyTranslation keyTranslation="form.global.label.coliseum-card-glow-color" defaultValue="Coliseum card glow color" /> }
            >
              <ChoiceColor
                defaultColor={ getValue(meta, "coliseum_glow") }
                uploadMutation={ setMutationVariableEeMetaSet }
                loadingMutationStandMetaSet={ loadingMutationEeMetaSet }
                btnText={ <KeyTranslation keyTranslation="button.global.choose-coliseum-card-glow-color" defaultValue="Choose Coliseum card glow color" /> }
                variables={
                  {
                    eeMetaSetEeId: exbnEvnt.id,
                    eeMetaSetInput: [ {
                      meta_key: "coliseum_glow",
                      meta_group: "ee_theme",
                      meta_type: 'string'
                    } ]
                  }
                }
              />
            </Form.Item>
          </Form.Item>

          {
            radioValueMain === 'image' && (
              <div className="row-grid md col-md-2">
                <Form.Item
                  className="image-page-cutomization"
                  label={ <KeyTranslation keyTranslation="form.global.label.exhibition-background-image" defaultValue="Exhibition background image" /> }
                  initialValue={ getValue(meta, "ee_main_bg") }
                  name="ee_main_bg-image-unset"
                >
                  <AvatarUpload
                    uploadMutation={ _setMutationVariableEeUpload }
                    image={ getValue(meta, "ee_main_bg") }
                    loading={ loadingMutationEeUpload }
                    variables={
                      {
                        ee_id: exbnEvnt.id,
                        meta_key: "ee_main_bg",
                        meta_type: 'image',
                        meta_group: "ee_theme",
                      }
                    }
                    extraClass={ "cover" }
                    uploadButtonText={ <KeyTranslation keyTranslation="button.global.upload-image" defaultValue="Upload Image" /> }
                    shape={ "square" }
                  />
                </Form.Item>

                <Form.Item
                  label={ <KeyTranslation keyTranslation="form.global.label.exhibition-header-image" defaultValue="Exhibition header image" /> }
                  className="image-page-cutomization"
                  initialValue={ getValue(meta, "ee_main_header_bg") }
                  name="ee_main_header_bg-image-unset"
                >
                  <AvatarUpload
                    uploadMutation={ _setMutationVariableEeUpload }
                    image={ getValue(meta, "ee_main_header_bg") }
                    loading={ loadingMutationEeUpload }
                    variables={
                      {
                        ee_id: exbnEvnt.id,
                        meta_key: "ee_main_header_bg",
                        meta_type: 'image',
                        meta_group: "ee_theme",
                      }
                    }
                    extraClass={ "cover" }
                    uploadButtonText={ <KeyTranslation keyTranslation="button.global.upload-image" defaultValue="Upload Image" /> }
                    shape={ "square" }
                  />
                </Form.Item>
              </div>
            )
          }

          <Typography.Link className="text-decorator">
            <h6><KeyTranslation keyTranslation="heading.small.form.exhibition.exhibition-e-mail" defaultValue="Exhibition E-mail" /></h6>
            <div className="separator"></div>
          </Typography.Link>

          <div className="row-grid md col-md-2">
            <Form.Item
              className="image-page-cutomization"
              initialValue={ getValue(meta, "ee_logo_email") }
              label={ <KeyTranslation keyTranslation="form.global.label.logo-emails" defaultValue="Logo for emails" /> }
              name="ee_logo_email-image-unset"
            >
              <AvatarUpload
                uploadMutation={ _setMutationVariableEeUpload }
                image={ getValue(meta, "ee_logo_email") }
                loading={ loadingMutationEeUpload }
                variables={
                  {
                    ee_id: exbnEvnt.id,
                    meta_key: "ee_logo_email",
                    meta_type: 'image',
                    meta_group: "ee_theme",
                  }
                }
                extraClass={ "contain" }
                uploadButtonText={ <KeyTranslation keyTranslation="button.global.upload-image" defaultValue="Upload Image" /> }
                shape={ "square" }
              />
            </Form.Item>

            <div>
              <Form.Item
                label={ <KeyTranslation keyTranslation="form.global.label.e-mail-primary-color" defaultValue="E-Mail primary color" /> }
              >
                <ChoiceColor
                  defaultColor={ getValue(meta, "primary_email_color") }
                  uploadMutation={ setMutationVariableEeMetaSet }
                  loadingMutationStandMetaSet={ loadingMutationEeMetaSet }
                  btnText={ <KeyTranslation keyTranslation="button.global.choose-e-mail-primary-color" defaultValue="Choose E-Mail primary color" /> }
                  variables={
                    {
                      eeMetaSetEeId: exbnEvnt.id,
                      eeMetaSetInput: [ {
                        meta_key: "primary_email_color",
                        meta_group: "ee_theme",
                        meta_type: 'string'
                      } ]
                    }
                  }
                />
              </Form.Item>

              <Form.Item
                label={ <KeyTranslation keyTranslation="form.global.label.e-mail-header-background-color" defaultValue="E-Mail header background color" /> }
              >
                <ChoiceColor
                  defaultColor={ getValue(meta, "header_bg_email_color") }
                  uploadMutation={ setMutationVariableEeMetaSet }
                  loadingMutationStandMetaSet={ loadingMutationEeMetaSet }
                  btnText={ <KeyTranslation keyTranslation="button.global.choose-e-mail-header-background-color" defaultValue="Choose E-Mail header background color" /> }
                  variables={
                    {
                      eeMetaSetEeId: exbnEvnt.id,
                      eeMetaSetInput: [ {
                        meta_key: "header_bg_email_color",
                        meta_group: "ee_theme",
                        meta_type: 'string'
                      } ]
                    }
                  }
                />
              </Form.Item>
            </div>
          </div>


          <Form.Item wrapperCol={ { sm: { span: 16, offset: 0 } } } >
            <Button loading={ loadingMutationEeMetaSet } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>
              <KeyTranslation keyTranslation="button.global.save" defaultValue="Save" />
            </Button>
          </Form.Item>

        </Form>
      )
      }
    </div>
  );
};

export default NormalEventThemeForm;
