import React from "react";
import { Switch, Route } from "react-router-dom";


import Dashboard from "./pages/dashboard";

import UsersPage from "./pages/users-page";
import ProfilePage from "./pages/profile-page";
import AddUserPage from "./pages/add-user-page";

import ExhibitionEventPage from "./pages/exhibition-event-page";
import ExhibitionEventsPage from "./pages/exhibition-events-page";
import AddExhibitionEventPage from "./pages/add-exhibition-event-page";

import ShowroomPage from "./pages/showroom-page";
import ShowroomsPage from "./pages/showrooms-page";
import AddShowroomPage from "./pages/add-showroom-page";

import LocalizationLangsPage from "./pages/localization-langs-page";
import LocalizationKeysPage from "./pages/localization-keys-page";
import LocalizationTrnslnsPage from "./pages/localization-trnslns-page";
import LocalizationTrnslnPage from "./pages/localization-trnsln-page";

import ProductsPage from "./pages/products-page";
import AddProductPage from "./pages/add-product-page";
import ProductPage from "./pages/product-page";

import TaxonomiesPage from "./pages/taxonomies-page";
import AddTaxonomyPage from "./pages/add-taxonomy-page";
import TaxonomyPage from "./pages/taxonomy-page";

import PackagesPage from "./pages/packages-page";
import AddPackagePage from "./pages/add-packages-page";
import PackagePage from "./pages/package-page";
import PackageRequestsPage from "./pages/package-requests-page";
import PackageRequestsPageResolved from "./pages/package-requests-resolved-page";




import NoPageFound from "./pages/no-page-found";


const Routes = () => {
  return (
    <Switch>

      <Route path="/" component={ Dashboard } exact disabled />

      <Route path="/users/" component={ UsersPage } exact />
      <Route path="/users/page-:numPage" component={ UsersPage } exact />
      <Route path="/users/add-user/" component={ AddUserPage } exact />
      <Route path="/users/id-:id-:fullName/" component={ ProfilePage } exact />
      <Route path="/users/id-:id-:fullName/:profileTab" component={ ProfilePage } exact />

      <Route path="/exhibition-events/" component={ ExhibitionEventsPage } exact />
      <Route path="/exhibition-events/page-:numPage" component={ ExhibitionEventsPage } exact />
      <Route path="/exhibition-events/add-event/" component={ AddExhibitionEventPage } exact />
      <Route path="/exhibition-events/:eventId" component={ ExhibitionEventPage } exact />
      <Route path="/exhibition-events/:eventId/:exhibitionEventTab/" component={ ExhibitionEventPage } exact />
      <Route path="/exhibition-events/:eventId/:exhibitionEventTab/page-:numPage" component={ ExhibitionEventPage } exact />

      <Route path="/showrooms/" component={ ShowroomsPage } exact />
      <Route path="/showrooms/page-:numPage" component={ ShowroomsPage } exact />
      <Route path="/showrooms/add-showroom/" component={ AddShowroomPage } exact />
      <Route path="/showrooms/:standId" component={ ShowroomPage } exact />
      <Route path="/showrooms/:standId/:standTab" component={ ShowroomPage } exact />
      <Route path="/showrooms/:standId/:standTab/page-:numPage" component={ ShowroomPage } exact />

      <Route path="/localization" component={ LocalizationLangsPage } exact />
      <Route path="/localization/languages" component={ LocalizationLangsPage } exact />
      <Route path="/localization/languages/page-:numPage" component={ LocalizationLangsPage } exact />

      <Route path="/localization/translation-keys" component={ LocalizationKeysPage } exact />
      <Route path="/localization/translation-keys/page-:numPage" component={ LocalizationKeysPage } exact />

      <Route path="/localization/translations" component={ LocalizationTrnslnsPage } exact />
      <Route path="/localization/translations/page-:numPage" component={ LocalizationTrnslnsPage } exact />
      <Route path="/localization/translations/:trnslnId" component={ LocalizationTrnslnPage } exact />

      <Route path="/products/" component={ ProductsPage } exact />
      <Route path="/products/page-:numPage" component={ ProductsPage } exact />
      <Route path="/products/add-product/" component={ AddProductPage } exact />
      <Route path="/products/add-product/:standId" component={ AddProductPage } exact />
      <Route path="/products/:productId" component={ ProductPage } exact />

      <Route path="/taxonomy/" component={ TaxonomiesPage } exact />
      <Route path="/taxonomy/page-:numPage" component={ TaxonomiesPage } exact />
      <Route path="/taxonomy/add-taxonomy/" component={ AddTaxonomyPage } exact />
      <Route path="/taxonomy/:txmyId" component={ TaxonomyPage } exact />
      <Route path="/taxonomy/:txmyId/:txmyTab" component={ TaxonomyPage } exact />

      <Route path="/packages/" component={ PackagesPage } exact />
      <Route path="/packages/page-:numPage" component={ PackagesPage } exact />
      <Route path="/packages/package-requests-resolved/" component={ PackageRequestsPageResolved } exact />
      <Route path="/packages/package-requests-resolved/page-:numPage" component={ PackageRequestsPageResolved } exact />
      <Route path="/packages/package-requests/" component={ PackageRequestsPage } exact />
      <Route path="/packages/package-requests/page-:numPage" component={ PackageRequestsPage } exact />
      <Route path="/packages/add-package/" component={ AddPackagePage } exact />
      <Route path="/packages/:pckgId" component={ PackagePage } exact />
      <Route path="/packages/:pckgId/:pckgTab" component={ AddPackagePage } exact />
      <Route path="/packages/:pckgId/:pckgTab/page-:numPage" component={ AddPackagePage } exact />

      <Route component={ NoPageFound } />

    </Switch>
  );
};

export default Routes;

