import React from "react";
import ExhibitionEventsTable from "./exhibition-events-table";
import { Layout } from 'antd';
import useQueryExbnEvnts from './hooks/use-query-exbn-evnts';
import KeyTranslation from '../service/key-translation';
import { GET_EXBN_EVNT_ALL } from "../../graphql/query/exhibition-gql";
import './exhibition-events.scss';



const { Sider, Content } = Layout;

const ExhibitionEvents = () => {

  const {
    loadingQuery,
    dataQuery,
    routePage,
    pagination,
    handleTableChange,
    rowCount
  } = useQueryExbnEvnts(GET_EXBN_EVNT_ALL);



  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>
            <KeyTranslation
              keyTranslation="heading.large.exhibition-events"
              defaultValue="Exhibition events" />
          </h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          {/* <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } /> */ }
        </Sider>
      </Layout>
      <ExhibitionEventsTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        loading={ loadingQuery }
        handleTableChange={ handleTableChange }
        routePage={ routePage }
        rowCount={ rowCount }
      />
    </>
  );
};

export default ExhibitionEvents;
