import { useReactiveVar } from "@apollo/client";
import { translationInterface } from '../../graphql/cache';
import translationFields from '../../utils/translation-fields';
import { Tooltip, Row, Col, Skeleton } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
// import RequestHandler from '../request-handler/request-handler';




const KeyTranslation = ({ keyTranslation, defaultValue = '' }) => {


    const { getValue, normalize } = translationFields;

    // GET dataQueryTranslation arr from reactive variable
    const translationReactiveVar = useReactiveVar(translationInterface)?.translations?.data;

    // arr normalize
    const translations = normalize(translationReactiveVar);

    // get keyTranslation value
    keyTranslation = getValue(translations, keyTranslation)



    return (
        <Skeleton loading={ !translationReactiveVar } active className="skeleton-key-translation">
            <Row gutter={ 16 } className="box-key-translation" style={ { display: 'inline-flex', flexFlow: 'row nowrap', margin: '0 -2px' } }>
                <Col className="gutter-row" style={ { padding: '0 2px' } }>
                    <span>{ isEmpty(keyTranslation) ? defaultValue : keyTranslation }</span>
                </Col>
                {
                    isEmpty(keyTranslation) && (
                        <Col className="gutter-row key-translation" style={ { padding: '0 2px', display: 'flex' } }>
                            <Tooltip title="No translation of the field has been entered for this language.">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </Col>
                    )
                }
            </Row>
        </Skeleton>
    );
};

export default KeyTranslation;




function isEmpty(str) {
    return (!str || 0 === str.length);
}







