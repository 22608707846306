import React from "react";
import { Spin } from "antd";
import useMutationStandMetaUpload from '../hooks/use-mutation-stand-meta-upload';
import AvatarUpload from '../../../upload/avatar/avatar-upload';
import metaFields from '../../../../utils/meta-fields';
import KeyTranslation from '../../../service/key-translation';
import { STAND_META_UPLOAD } from '../../../../graphql/mutation/showroom-gql';


const NormalDownloadPageForm = ({
  loadingQueryStand,
  dataQueryStand,
}) => {


  const {
    _setMutationStandUpload,
    loadingMutationStandUpload,

  } = useMutationStandMetaUpload(STAND_META_UPLOAD, dataQueryStand);

  const { getValue, normalize } = metaFields;
  const meta = normalize(dataQueryStand.meta_fields);


  return (

    loadingQueryStand ? (
      <div className="block-loader h-300px" >
        <Spin size="small" />
      </div>
    ) : (
        <div className="showroom-image">
          <AvatarUpload
            image={ getValue(meta, "downloads_image_1") }
            loading={ loadingMutationStandUpload }
            uploadMutation={ _setMutationStandUpload }
            variables={
              {
                input: {
                  stand_id: dataQueryStand.id,
                  meta_key: "downloads_image_1",
                  meta_group: "page_headers",
                  meta_type: "image"
                }
              }
            }
            uploadButtonText={ <KeyTranslation keyTranslation="button.global.upload-page-header-image" defaultValue="Upload Page header image" /> }
            shape={ "square" }
          />
        </div>
    )
  )
}


export default NormalDownloadPageForm;
