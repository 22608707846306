import React from "react";
import { Typography } from 'antd';
import NormalProductUpdateBookForm from './normal-product-update-book-form';
import useMutationProductCreateAndMetaSet from '../hooks/use-mutation-product-create-and-meta-set';
import useMutationProductDelete from '../hooks/use-mutation-product-delete';
import KeyTranslation from '../../../service/key-translation';


import {
  PRODUCT_CREATE_AND_META_SET,
  PRODUCT_DELETE
} from '../../../../graphql/mutation/products-gql'


import './product-update-book-form.scss';




const ProductUpdateBookForm = ({
  loadingQuery,
  errorQuery,
  dataQuery
}) => {

  const {
    _setMutationProductCreateAndMetaSet,
    loadingMutationProductCreateAndMetaSet,

  } = useMutationProductCreateAndMetaSet(PRODUCT_CREATE_AND_META_SET, dataQuery);


  const {
    _setMutationProductDelete,
    loadingProductDelete

  } = useMutationProductDelete(PRODUCT_DELETE, dataQuery);


  return (
    <>
      <h3><KeyTranslation keyTranslation="heading.middle.global.edit-product" defaultValue="Edit Product" /> <Typography.Link>{ dataQuery && dataQuery.title } </Typography.Link></h3>
      <NormalProductUpdateBookForm
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        errorQuery={ errorQuery }
        setMutationProductCreateAndMetaSet={ _setMutationProductCreateAndMetaSet }
        loadingMutationProductCreateAndMetaSet={ loadingMutationProductCreateAndMetaSet }
        setMutationProductDelete={ _setMutationProductDelete }
        loadingProductDelete={ loadingProductDelete }
      />
    </>
  );
};


export default ProductUpdateBookForm;
