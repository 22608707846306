import UploadFile from '../../upload/upload-files/upload-file';
import useMutationStandUploadFile from './hooks/use-mutation-stand-upload-file';
import KeyTranslation from '../../service/key-translation';
import { ATTACHED_UPLOAD } from './../../../graphql/mutation/attachments-gql';



const StandUploadFile = ({ dataQuery, disabledBtn }) => {


  const {
    _setMutationStandUploadFile,
    loadingMutationStandUploadFile
  } = useMutationStandUploadFile(ATTACHED_UPLOAD, dataQuery);


  return (

    <UploadFile
      className="stand-upload-file"
      // size={ 140 }
      loading={ loadingMutationStandUploadFile }
      uploadMutation={ _setMutationStandUploadFile }
      uploadButtonText={ <KeyTranslation keyTranslation="button.global.upload-file" defaultValue="Upload file" /> }
      disabledBtn={ disabledBtn }
      btnType="primary"
      accept={ '*' }
      variables={
        {
          stand_id: dataQuery.id,
          status: "draft",
        }
      }
    />

  );
};

export default StandUploadFile;
