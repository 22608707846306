import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_STAND_AMOUNTS } from "../graphql/query/showrooms-gql";



const useQueryStandAmounts = (query, standIdFromSelect = undefined) => {

    const { standId } = useParams();

    const {
        loading,
        data } = useQuery(query,
            {
                skip: !(!!standId || !!standIdFromSelect),
                variables: {
                    stand_id: standId || standIdFromSelect,
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: "cache-and-network",
            });

    return {
        dataQueryStandAmounts: !!data && data.standAmounts,
        loadingQueryStandAmounts: loading
    }
};



const MaxAmount = (dataQuery, ruleKey = '', entity = false) => {



    const {
        dataQueryStandAmounts,
        loadingQueryStandAmounts
    } = useQueryStandAmounts(GET_STAND_AMOUNTS);



    let amountActuallyOnStand = false;


    if (entity)
    {
        amountActuallyOnStand = dataQuery && dataQuery?.[ entity ].length;
    } else
    {
        amountActuallyOnStand = !loadingQueryStandAmounts && dataQueryStandAmounts[ ruleKey ]
    }


    const packageRules = dataQuery.package?.package_rules;
    const amountPackageRule = packageRules && (
        packageRules
            .filter(
                ({ rule_key }) => rule_key === ruleKey
            )
    );


    const maxAmountPackageRule = amountPackageRule && amountPackageRule[ 0 ]?.max_value;
    const activePackageRule = amountPackageRule && amountPackageRule[ 0 ]?.active;
    const permittedNumbersStand = (+amountActuallyOnStand >= +maxAmountPackageRule);
    const disabledEntityFromRules = packageRules ? (permittedNumbersStand || !activePackageRule) : true;


    // console.log('amountActuallyOnStand', +amountActuallyOnStand);
    // console.log('maxAmountPackageRule', +maxAmountPackageRule);
    // console.log('activePackageRule', !activePackageRule);
    // console.log('disabledEntityFromRules', disabledEntityFromRules);


    return {
        maxAmountPackageRule,
        activePackageRule,
        permittedNumbersStand,
        disabledEntityFromRules
    };
}




const MaxAmountForSelect = (standIdFromSelect, ruleKey, maxAmountPackageRule, activePackageRule) => {

    const {
        dataQueryStandAmounts,
        loadingQueryStandAmounts
    } = useQueryStandAmounts(GET_STAND_AMOUNTS, standIdFromSelect);



    const amountActuallyOnStand = !loadingQueryStandAmounts && dataQueryStandAmounts[ ruleKey ]
    const permittedNumbersStand = (+amountActuallyOnStand >= +maxAmountPackageRule);
    const disabledEntityFromRules = (permittedNumbersStand || !activePackageRule);


    // console.log('amountActuallyOnStand', +amountActuallyOnStand);
    // console.log('maxAmountPackageRule', +maxAmountPackageRule);
    // console.log('activePackageRule', !activePackageRule);
    // console.log('disabledEntityFromRules', disabledEntityFromRules);


    return {
        amountActuallyOnStand,
        permittedNumbersStand,
        disabledEntityFromRules,
    };
}





const packageRules = {
    packageRuleForStand: MaxAmount,
    packageRuleForStandSelect: MaxAmountForSelect,
};



export default packageRules;