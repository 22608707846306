import React from "react";
import ChangePasswordForm from "../../../forms/change-password-form";

const ProfileTabChangePassword = ({ getUser }) => {

    return (
        <ChangePasswordForm getUser={ getUser } />
    );
};

export default ProfileTabChangePassword;