import React, { useEffect, useState } from "react";
import { Form, Input, Radio } from "antd";
import metaFields from '../../../../utils/meta-fields';
import UploadFile from '../../../upload/upload-files';
import youtubeUrl from '../../../../utils/youtube-url';
import useMutationProductMetaDelete from '../hooks/use-mutation-product-meta-delete';
import KeyTranslation from '../../../service/key-translation';
import { PRODUCT_META_IMAGE_DELETE } from '../../../../graphql/mutation/products-gql';

import moment from 'moment';

import './product-media-form.scss';



const ProductMediaForm = ({
  dataQuery,
  setMutationProductUpload,
  loadingMutationProductUpload,
}) => {


  const {
    _setMutationProductMetaDelete,
    // loadingProductMetaDelete
  } = useMutationProductMetaDelete(PRODUCT_META_IMAGE_DELETE, dataQuery);


  const { getValue, getGroup, normalize } = metaFields;
  const meta = normalize(dataQuery.meta_fields);
  const productImages = sortImages(getGroup(meta, "product_photo"));

  const defaultRadioVal = getValue(meta, "product_media_type", 'image');
  const [ radioValue, setRadioValue ] = useState(defaultRadioVal);
  useEffect(() => {
    setRadioValue(defaultRadioVal)
  }, [ defaultRadioVal ]);


  const radioOnChange = e => {
    setRadioValue(e.target.value);
  }


  return (

    <>
      <Form.Item
        initialValue={ defaultRadioVal }
        name="product_media_type-radio"
        className="form-group"
        style={ { margin: "10px 0" } }>
        <Radio.Group onChange={ radioOnChange }>
          <Radio.Button value="image">Add image</Radio.Button>
          <Radio.Button value="video">Add video</Radio.Button>
        </Radio.Group>
      </Form.Item>

      { radioValue === 'video' &&
        <>
          <Form.Item
            name="product_video_link-link"
            label={ <KeyTranslation keyTranslation="form.global.label.youtube-video-link" defaultValue="YouTube video link" /> }
            initialValue={ getValue(meta, "product_video_link") }
            rules={
              [
                () => ({
                  validator(_, value) {
                    if (youtubeUrl.parse(value))
                    {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Please insert valid youtube link'))
                  }
                })
              ]
            }
          >
            <Input placeholder="Enter your product link (only youtube)" />
          </Form.Item>

          <Form.Item
            className="grid-col-2-upload-visible-btn remove-btn-hidden"
            label={ <KeyTranslation keyTranslation="form.global.label.cover-image" defaultValue="Cover image" /> }
            name="cover-image-unset"
            initialValue={ dataQuery.cover }
          >
            <UploadFile
              // size={ 140 }
              url={ dataQuery.cover }
              loading={ loadingMutationProductUpload }
              uploadMutation={ setMutationProductUpload }
              uploadButtonText={ <KeyTranslation keyTranslation="button.global.upload-cover-image" defaultValue="Upload Cover image" /> }
              accept={ 'image/*' }
              listType={ 'picture-card' }
              variables={
                {
                  input: {
                    product_id: dataQuery.id,
                    meta_key: "cover",
                  }
                }
              }
            />
          </Form.Item>
        </>
      }

      { radioValue === 'image' &&
        <>
          <Form.Item
            className="grid-col-2-upload-visible-btn remove-btn-hidden"
            label={ <KeyTranslation keyTranslation="form.global.label.cover-image" defaultValue="Cover image" /> }
            name="cover-image-unset"
            initialValue={ dataQuery.cover }
          >
            <UploadFile
              // size={ 140 }
              maxCountFileList={ 1 }
              url={ dataQuery.cover }
              loading={ loadingMutationProductUpload }
              uploadMutation={ setMutationProductUpload }
              uploadButtonText={ <KeyTranslation keyTranslation="button.global.upload-cover-image" defaultValue="Upload Cover image" /> }
              accept={ 'image/*' }
              listType={ 'picture-card' }
              variables={
                {
                  input: {
                    product_id: dataQuery.id,
                    meta_key: "cover",
                  }
                }
              }
            />
          </Form.Item>

          <Form.Item
            className={ `grid-col-2-upload-visible-btn card-list ${ productImages.length <= 3 ? '' : 'upload-btn-none' } ` }
            label={ <KeyTranslation keyTranslation="form.global.label.additional-slides" defaultValue="Additional slides" /> }
          >

            { productImages.length === 0 &&
              (
                <UploadFile
                  maxCountFileList={ 1 }
                  loading={ loadingMutationProductUpload }
                  uploadMutation={ setMutationProductUpload }
                  uploadButtonText={ <KeyTranslation keyTranslation="button.global.add-image" defaultValue="Add image" /> }
                  accept={ 'image/*' }
                  listType={ 'picture-card' }
                  variables={
                    {
                      input: {
                        product_id: dataQuery.id,
                        meta_key: `product_image_1`,
                        meta_type: "image",
                        meta_group: "product_photo"
                      }
                    }
                  }
                />
              )
            }


            { productImages.map(

              (item, index) => {
                return (

                  <UploadFile
                    maxCountFileList={ 1 }
                    key={ item.id }
                    url={ `${ item.meta_value }?${ moment().format('LTS') }` }
                    loading={ loadingMutationProductUpload }
                    uploadMutation={ setMutationProductUpload }
                    uploadButtonText={ <KeyTranslation keyTranslation="button.global.add-image" defaultValue="Add image" /> }
                    accept={ 'image/*' }
                    listType={ 'picture-card' }
                    variables={
                      {
                        input: {
                          product_id: dataQuery.id,
                          meta_key: `product_image_${ index + 2 }`,
                          meta_type: "image",
                          meta_group: "product_photo"
                        }
                      }
                    }
                    productImages={ productImages }
                    deleteMutation={ _setMutationProductMetaDelete }
                    deleteVariables={
                      {
                        product_id: dataQuery.id,
                        meta_key: item.meta_key,
                      }
                    }
                  />
                );
              })
            }

          </Form.Item>
        </>
      }
    </>
  );
}

export default ProductMediaForm;


const sortImages = (productImages) => {

  productImages.sort(function (a, b) {
    if (a.meta_key > b.meta_key)
    {
      return 1;
    }
    if (a.meta_key < b.meta_key)
    {
      return -1;
    }
    return 0;
  });

  return productImages;
}

