import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Tabs, Typography } from "antd";
import PackageCreateForm from '../../forms/packages-form/package-create-form';
import PackageRules from './../../packages/package-rules/package-rules';
import KeyTranslation from '../../service/key-translation';
import mobileSize from '../../../utils/mobile-size';
import { GET_PACKAGE } from '../../../graphql/query/packages-gql';

import './package-tabs.scss';








const PackageTabs = () => {

    const { TabPane } = Tabs;

    const { pckgId, pckgTab } = useParams();
    let history = useHistory();


    const { data, loading } = useQuery(GET_PACKAGE, {
        skip: !pckgId,
        variables: {
            id: pckgId
        },
    });



    const singlePackage = (!loading && data?.package) || false;


    return (
        <div className="tabs-form package-tabs">
            {
                !loading && (!pckgId ?
                    <h3><KeyTranslation keyTranslation="heading.middle.packages.add-package" defaultValue="Add Package" /></h3> :
                    <h3><KeyTranslation keyTranslation="heading.middle.packages.edit-package" defaultValue="Edit Package" /> <Typography.Link>{ singlePackage.title }</Typography.Link></h3>)
            }

            <Tabs
                type="card"
                tabPosition={ mobileSize(true, 1300) ? 'top' : 'left' }
                activeKey={ pckgTab ? pckgTab : 'package' }
                onTabClick={ (key) => {
                    changeTab(history, key, pckgId);
                } }
            >
                <TabPane tab={ <KeyTranslation keyTranslation="tab.packages.package" defaultValue="Package" /> } key="package">
                    <PackageCreateForm
                        dataQuery={ singlePackage }
                        loadingQuery={ loading } />
                </TabPane>
                { !!pckgId && (
                    <>
                        <TabPane tab={ <KeyTranslation keyTranslation="tab.packages.rules" defaultValue="Rules" /> } key="rules">
                            <PackageRules pckgId={ pckgId } />
                        </TabPane>
                    </>
                ) }
            </Tabs>
        </div>
    );
};


export default PackageTabs;


//Pure functions

const changeTab = (history, tabKey, pckgId) => {

    if (!!pckgId)
    {
        if (tabKey === 'package')
        {
            history.push(`/packages/${ pckgId }`);
        } else
        {
            history.push(`/packages/${ pckgId }/${ tabKey }`);
        }
    } else
    {
        history.push(`/packages/add-package`);
    }
};