import React from "react";
import { Link } from 'react-router-dom';
import { Table, Tag } from "antd";
import mobileSize from "../../../../utils/mobile-size";
import arrSortDescend from '../../../../utils/arr-sort-descend';
import KeyTranslation from '../../../service/key-translation';

import "./package-requests-stand-table.scss"



const { Column } = Table;


const PackageRequestsStandTable = ({
  dataQuery,
  loadingQuery,
}) => {


  const arrDescend = dataQuery && arrSortDescend(dataQuery.package_request);

  return (
    <div className="_holder-table">
      <Table size={ mobileSize('small') }
        className="main-table"
        loading={ loadingQuery }
        dataSource={ arrDescend }
        rowKey={ item => item.id }
      >
        <Column title="ID" dataIndex="id" key="id" />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.package" defaultValue="Package" /> }
          render={ item =>
            <Link
              to={ {
                pathname: `/packages/${ item.package.id }`,
                state: {
                  breadcrumbSlug: item.package.title
                }
              } }
            >
              { `${ item.package.title }` }
            </Link> }
        />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.user" defaultValue="User" /> }
          render={
            item =>
              <>
                { !!item.user?.id ?
                  <Link
                    to={ {
                      pathname: `/users/id-${ item.user?.id }-${ item.user?.name }-${ item.user?.surname }`.toLowerCase(),
                    } }
                  >
                    { item.user?.name } { item.user?.surname }
                  </Link>
                  :
                  'User not provided' }
              </>
          }
        />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.moderator" defaultValue="Moderator" /> }
          render={
            item =>
              <>
                { !!item.moderator?.id ?
                  <Link
                    to={ {
                      pathname: `/users/id-${ item.moderator?.id }-${ item.moderator?.name }-${ item.moderator?.surname }`.toLowerCase(),
                    } }
                  >
                    { item.moderator?.name } { item.moderator?.surname }
                  </Link>
                  :
                  'The moderator has not yet processed the request' }
              </>
          }
        />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.description" defaultValue="Description" /> }
          dataIndex="message_answer" key="message_answer" />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.status" defaultValue="Status" /> }
          render={ item =>
            item?.status === 'approved' ?
              <Tag color="green">{ item.status }</Tag> :

              item?.status === 'declined' ?
                <Tag color="red">{ item.status }</Tag> :
                <Tag>{ item.status }</Tag>
          } />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.created" defaultValue="Created" /> }
          dataIndex="created_at" key="created_at" />
      </Table>
    </div>
  );
};

export default PackageRequestsStandTable;


