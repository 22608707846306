import React from "react";
import { useParams } from "react-router-dom";
import NormalProductCreateForm from './normal-product-create-form';
import useMutationProductCreateUpdate from '../hooks/use-mutation-product-create-update';
import KeyTranslation from '../../../service/key-translation';

import {
  PRODUCT_CREATE_UPDATE
} from '../../../../graphql/mutation/products-gql'


import './product-create-form.scss';

// const { Text } = Typography;

const ProductCreateForm = () => {

  const {
    _setMutationProductCreateUpdate,
    loadingMutationProductCreateUpdate

  } = useMutationProductCreateUpdate(PRODUCT_CREATE_UPDATE, false);

  const { standId = false } = useParams();


  return (
    <>
      <h3><KeyTranslation keyTranslation="heading.middle.product.add-product" defaultValue="Add Product" /></h3>
      <NormalProductCreateForm
        standId={ standId }
        setMutationProductCreateUpdate={ _setMutationProductCreateUpdate }
        loadingMutationProductCreateUpdate={ loadingMutationProductCreateUpdate }
      />
    </>
  );
};


export default ProductCreateForm;
