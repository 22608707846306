import { useMutation } from "@apollo/client";
import { errorNotification } from "../../../result";
import { isLoggedInVar } from '../../../../graphql/cache';


const useMutationLogin = (mutation) => {

  const [ loginRequest, { loading } ] = useMutation(mutation, {
    update(cache, { data: { userLogin } }) {

      const { access_token } = userLogin;
      localStorage.setItem("authToken", access_token);
      isLoggedInVar(access_token);
    },
    onError(error) {
      errorNotification(error);
    }
  });

  return {
    loading,
    loginRequest
  }
};

export default useMutationLogin;