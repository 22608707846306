import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import convertSortName from './../../../utils/convert-sort-name';
import { gqlWhere } from '../../../utils/gql-where';
import cache from './../../../graphql/cache';




const useQueryUsers = (query) => {

  const routePage = `/users/`;

  const [ standId, setStandId ] = useState(undefined);
  const [ eeId, setEeId ] = useState(undefined);
  const [ pageSize, setPageSize ] = useState(10);
  const [ searchVal, setSearchVal ] = useState("");
  const [ order, setOrder ] = useState({
    orderBy: [
      {
        column: "ID",
        order: 'ASC'
      }
    ]
  });


  const [ filter, setFilters ] = useState(false);


  let history = useHistory();
  const { numPage } = useParams();

  const variables = {
    first: pageSize,
    page: +numPage ? +numPage : 1,
    where: { ...filter },
    stand: standId && {
      column: 'ID',
      operator: 'LIKE',
      value: standId
    },
    exhibition_events: eeId && {
      column: 'ID',
      operator: 'LIKE',
      value: eeId
    },
    ...order,
    text: searchVal,
  }


  const { loading, error, data } = useQuery(query, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const { users = {} } = !loading ? data : {};
  const { paginatorInfo = {} } = !loading ? users : {};
  const { total, currentPage } = paginatorInfo;


  const pagination = {
    current: currentPage,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };


  const handleTableChange = (pagination, filters, sorter, page) => {

    setPageSize(pagination.pageSize)

    const {
      order,
      columnKey
    } = sorter;


    setOrder({
      orderBy: [
        {
          column: !!columnKey ? columnKey.toUpperCase() : 'ID',
          order: !!convertSortName(order) ? convertSortName(order) : 'ASC'
        }
      ]
    });


    setFilters(gqlWhere(filters))

    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }page-${ pagination.current }`);
    }
  }

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: users,
    setSearchVal,
    routePage,
    history,
    pagination,
    handleTableChange,
    setStandId,
    setEeId,
    rowCount: cache.readQuery({ query, variables })?.users?.paginatorInfo?.count || 10
  }
};

export default useQueryUsers;

