import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";
import { GET_ATTACHMENTS_STAND } from "../../../../graphql/query/attachments-gql";
import { GET_STAND_AMOUNTS } from "../../../../graphql/query/showrooms-gql";



const useMutationAttachedDelete = (mutation, dataQuery, standId) => {


  const [ _setMutationAttachedDelete,
    { loading, error } ] = useMutation(mutation,
      {
        update(cache, { data }) {

          const {
            attachedDelete: {
              label,
              message
            }
          } = data;



          const filteredArr = dataQuery.data.filter(
            (item) => item.id !== dataQuery.data.id
          )


          cache.writeQuery({
            query: GET_ATTACHMENTS_STAND,
            variables: {
              id: dataQuery.id
            },
            data: {
              attachmentsStand: {
                data: [
                  filteredArr
                ],
                __typename: "AttachmentPaginator"
              }
            }
          })



          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },

        refetchQueries: [
          {
            query: GET_STAND_AMOUNTS,
            variables: { stand_id: standId },
          },
        ]
      });

  return {
    _setMutationAttachedDelete,
    loadingAttachedDelete: loading,
    errorMutationAttachedDelete: error,
  }
};

export default useMutationAttachedDelete;