import React from "react";
import { Link } from 'react-router-dom';
import { Table, Typography, Button } from "antd";
import mobileSize from "../../../../utils/mobile-size";
import SelecStands from '../../../forms/select-stands';
import SelectUserStand from '../../../forms/select-users-stand';
import SelectPackages from '../../../forms/select-packages';
import SelectUserModerator from '../../../forms/select-user-moderator';
import TableColumnSelectFilter from '../../../service/table-column-select-filter';
import KeyTranslation from '../../../service/key-translation';
import SkeletonTable from '../../../service/skeleton-table';
import "./package-requests-resolved-table.scss"




const { Column } = Table;
const { Text } = Typography;




const PackageRequestsResolvedTable = ({
  dataQuery,
  loadingQuery,
  pagination,
  handleTableChange,
  setStandId,
  setPackageId,
  setUserId,
  setModeratorId,
  rowCount
}) => {



  return (
    <div className="holder-table">
      <Table size={ mobileSize('small') }
        className="main-table"
        tableLayout={ mobileSize(true, 1300) ? '' : 'fixed' }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        loading={ { spinning: loadingQuery, indicator: <SkeletonTable rowCount={ rowCount } columns={ "".split.call(Array(10), ",") } /> } }
        dataSource={ dataQuery }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column
          className="column-width-70"
          title="ID" sorter="true" dataIndex="id" key="id" />

        { TableColumnSelectFilter({
          setFilter: setPackageId,
          columnTitle: <KeyTranslation keyTranslation="table.global.column.head.package" defaultValue="Package" />,
          columnKey: 'Package',
          columnSelectFilter: <SelectPackages />,
          columnRender: item =>
            <Link
              to={ {
                pathname: `/packages/${ item.package_id }`,
                state: {
                  breadcrumbSlug: item.package.title
                }
              } }
            >
              { `${ item.package.title }` }
            </Link>
          ,
          columnFormOnFinish: (values, setFilter) => {
            setFilter(values.package_id)
          },
        })
        }


        { TableColumnSelectFilter({
          setFilter: setStandId,
          columnTitle: <KeyTranslation keyTranslation="table.global.column.head.showroom" defaultValue="Showroom" />,
          columnKey: 'Showroom',
          columnSelectFilter: <SelecStands />,
          columnRender: item =>
            <>
              <Link
                to={ {
                  pathname: `/showrooms/${ item.stand_id }`,
                  state: {
                    breadcrumbSlug: item.stand.title
                  }
                } }
              >
                { `${ item.stand.title }` }
              </Link>
              <br />
              {
                item.stand.package?.title ?
                  <>
                    Current Package: <Text type="success">{ item.stand.package?.title }</Text>
                  </>
                  :
                  <>
                    Current Package: <Text type="secondary">This stand does not have a package</Text>
                  </>
              }

            </>
          ,
          columnFormOnFinish: (values, setFilter) => {
            setFilter(values[ 'stand_id--unset' ].value)
          },
        })
        }


        { TableColumnSelectFilter({
          setFilter: setUserId,
          columnTitle: <KeyTranslation keyTranslation="table.global.column.head.user" defaultValue="User" />,
          columnKey: 'User',
          columnSelectFilter: <SelectUserStand selectLabel={ 'User' } />,
          columnRender: item =>
            <>
              { !!item.user?.id ?
                <Link
                  to={ {
                    pathname: `/users/id-${ item.user?.id }-${ item.user?.name }-${ item.user?.surname }`.toLowerCase(),
                  } }
                >
                  { item.user?.name } { item.user?.surname }
                </Link>
                :
                'User not provided' }
            </>
          ,
          columnFormOnFinish: (values, setFilter) => {
            setFilter(values.stand_user)
          },
        })
        }


        { TableColumnSelectFilter({
          setFilter: setModeratorId,
          columnTitle: <KeyTranslation keyTranslation="table.global.column.head.moderator" defaultValue="Moderator" />,
          columnKey: 'Moderator',
          columnSelectFilter: <SelectUserModerator />,
          columnRender: item =>
            <Link
              to={ {
                pathname: `/users/id-${ item.moderator_id }-${ item.moderator?.name }-${ item.moderator?.surname }`.toLowerCase(),
              } }
            >
              { `${ item.moderator?.name } ${ item.moderator?.surname }` }
            </Link>
          ,
          columnFormOnFinish: (values, setFilter) => {
            setFilter(values.package_moderator)
          },
        })
        }

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.description" defaultValue="Description" /> }
          dataIndex="message_answer" key="message_answer" />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.status" defaultValue="Status" /> }
          key="status"
          // filterMultiple={ false }
          filters={ [
            { text: "Approved", value: "approved" },
            { text: "Declined ", value: "declined" },
          ] }
          render={ item =>
            item.status === 'approved' ?
              <Text type="success">{ item.status }</Text> :
              <Text type="danger">{ item.status }</Text>
          }
        />

        <Column sorter="true" title="Created" dataIndex="created_at" key="created_at" />

        <Column
          title={ <KeyTranslation keyTranslation="table.global.column.head.action" defaultValue="Action" /> }
          render={ item =>
            <Link
              to={ {
                pathname: `/packages/${ item.package_id }`,
                state: {
                  breadcrumbSlug: item.package.title
                }
              } }
            >
              <Button type="primary">Edit</Button>
            </Link> } />
      </Table>
    </div>
  );
};

export default PackageRequestsResolvedTable;

